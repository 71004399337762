//useAxios.js
import { useMemo } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "./AuthContext";


const useAxios = () => {
    const internalUrl = process.env.REACT_APP_API_BASE_URL;
    const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}/api` : 'http://127.0.0.1:8000/api';
    }, [internalUrl]);

  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const axiosInstance = axios.create({
    url,
    headers: authTokens ? { Authorization: `Bearer ${authTokens.access}` } : {}
  });

  axiosInstance.interceptors.request.use(async req => {
    if (!authTokens) return req; // If not authenticated, skip token refresh

    const user = jwtDecode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    const response = await axios.post(`${url}/token/refresh/`, {
      refresh: authTokens.refresh
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));

    setAuthTokens(response.data);
    setUser(jwtDecode(response.data.access));

    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });

  return axiosInstance;
};

export default useAxios;