import * as React from 'react';
import { useContext } from "react";
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import AuthContext from '../../utils/AuthContext';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

export default function Verify() {
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(120); // 2 minutes in seconds
  const [canResend, setCanResend] = useState(false);
  const { verifyUser } = useContext(AuthContext);

  useEffect(() => {
    // Start the timer when component mounts
    startResendTimer();
  }, []);

  const startResendTimer = () => {
    setCanResend(false);
    setResendTimer(120);

    const timer = setInterval(() => {
      setResendTimer((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setCanResend(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleResend = async () => {
    if (!canResend) return;

    setLoading(true);
    try {
      // const response = await axios.post('/api/resend-code/');
      setMessage('Verification code has been resent to your email');
      startResendTimer(); // Restart the timer
    } catch (error) {
      setMessage(error.response?.data?.error || 'Failed to resend verification code');
    } finally {
      setLoading(false);
    }
  };

  const validateCode = () => {
    let isValid = true;

    if (!code || code.length < 6) {
      setCodeError(true);
      setCodeErrorMessage('Please enter a valid verification code.');
      isValid = false;
    } else {
      setCodeError(false);
      setCodeErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateCode()) {
      return;
    }
    setLoading(true);
    await verifyUser(code, setCodeErrorMessage);
    setLoading(false);

  };

  return (
    <Card variant="outlined">
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Verify Your Account
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <FormControl fullWidth error={codeError}>
          <FormLabel htmlFor="code">Verification Code</FormLabel>
          <TextField
            required
            autoFocus
            id="code"
            name="code"
            type="text"
            placeholder="Enter your verification code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            helperText={codeError ? codeErrorMessage : ''}
          />
        </FormControl>
        
        {message && (
          <Typography 
            color={message.includes('error') ? 'error' : 'success.main'}
            sx={{ textAlign: 'center' }}
          >
            {message}
          </Typography>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
        </Button>

        <Typography sx={{ textAlign: 'center' }}>
          Didn't receive a code?{' '}
          {canResend ? (
            <Link
              component="button"
              variant="body2"
              onClick={handleResend}
              sx={{ cursor: 'pointer' }}
            >
              Resend
            </Link>
          ) : (
            <Typography
              component="span"
              variant="body2"
              color="text.secondary"
            >
              Resend available in {formatTime(resendTimer)}
            </Typography>
          )}
        </Typography>

        <Typography sx={{ textAlign: 'center' }}>
          Back to{' '}
          <Link
            href="/login"
            variant="body2"
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </Card>
  );
}