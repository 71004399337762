import * as React from 'react';
import { useContext, useEffect, useMemo} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword';
import { GoogleIcon, FacebookIcon } from './CustomIcons';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import AuthContext from '../../utils/AuthContext';
import { useTranslation } from 'react-i18next';

const Card = styled(MuiCard)(({ theme }) => ({
  borderColor: 'transparent',
  display: 'flex',
  backgroundColor:'transparent',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

export default function SignInCard({next}) {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [usernameError, setUsernameError] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [loginError, setLoginError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [registerMessage, setRegisterMessage] = React.useState('');

  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );
  
  const navigate = useNavigate();
  const { loginUser } = useContext(AuthContext);

  const handleNext = (next) => {
    navigate(next);
  }

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Retrieve and show the registration success message, then clear it
    const message = localStorage.getItem("registerMessage");
    if (message) {
      setRegisterMessage(message);
      localStorage.removeItem("registerMessage");
    }
  }, []);

  const fetchMembershipData = async (authTokens) => {
    try {
      const response = await fetch(`${url}/api/subscription/membership/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`
          }
        }
      );
      const data = response.json();  // Parse the JSON response
      return data;
    } catch (error) {
      console.error("Error fetching membership data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate inputs
    if (!validateInputs()) {
      return;
    }
    setLoading(true);
    const [success, authTokens] = await loginUser(username, password, setLoginError);

    if (success) {
      const membershipData = await fetchMembershipData(authTokens);
      localStorage.setItem("username", membershipData?.subscription?.user?.username);
      localStorage.setItem("email", membershipData?.subscription?.user?.email);
      localStorage.setItem("company", membershipData?.subscription?.customer?.company_name);
      handleNext(next);
    }
    setLoading(false);
  };

  const validateInputs = () => {
    let isValid = true;

    if (!username || username.length < 6) {
      setUsernameError(true);
      setUsernameErrorMessage(t('Username must be at least 6 characters long.'));
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage(t('Password must be at least 6 characters long.'));
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  return (
    <Card variant="outlined">
      <Typography
        component="h1"
        fontStyle="bold"
        variant="h6"
        sx={{ width: '100%', fontSize: 'clamp(3rem, 20vw, 2.15rem)' }}
      >
        {t("Sign in")}
      </Typography>
      {registerMessage && (
        <Typography color="success.main" sx={{ textAlign: "center" }}>
          {registerMessage}
        </Typography>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl fullWidth error={usernameError}>
          <FormLabel htmlFor="username"/>
          <TextField
            id="username"
            type="text"
            name="username"
            placeholder={t("Your Username")}
            autoComplete="username"
            autoFocus
            required
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            helperText={usernameError ? usernameErrorMessage : ''}
            error={usernameError}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                borderColor: 'black',
                border: 1
              },
            }}
          />
        </FormControl>
        <FormControl fullWidth error={passwordError}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '50px', mb: 2}}>
            <FormLabel htmlFor="password"/>
            <Link
              component="button"
              type="button"
              onClick={handleClickOpen}
              variant="body2"
              sx={{ alignSelf: 'baseline', color: '#1c4ed8' }}
            >
              {t("Forgot your password?")}
            </Link>
          </Box>
          <TextField
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            required
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={passwordError ? passwordErrorMessage : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                borderColor: 'black',
                border: 1,
              },
            }}
          />
        </FormControl>
        {loginError && <Typography color="error">{loginError}</Typography>} {/* Display login error */}
        <ForgotPassword open={open} handleClose={handleClose} />
        <Button type="submit" fullWidth variant="contained" sx={{borderRadius: '50px', backgroundColor: '#1c4ed8'}} >
          {loading ? <CircularProgress size={24} color="white" /> : t('Sign in')}
        </Button>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={t("Remember me")}
        />
        <Typography sx={{ textAlign: 'center' }}>
          {t("Don't have an account?")}{' '}
          <Link href="/register/" variant="body2">
            {t("Sign up")}
          </Link>
        </Typography>
      </Box>
      <Divider>{t("or")}</Divider>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => alert(t('Sign in with Google'))}
          startIcon={<GoogleIcon />}
          sx={{
              borderRadius: '50px',
              borderColor: '#1c4ed8',
              color: '#1c4ed8'
          }}
        >
          {t("Sign in with Google")}
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => alert(t('Sign in with Facebook'))}
          startIcon={<FacebookIcon />}
          sx={{
            borderRadius: '50px',
            borderColor: '#1c4ed8',
            color: '#1c4ed8'
        }}
        >
          {t("Sign in with Facebook")}
        </Button>
      </Box>
    </Card>
  );
}
