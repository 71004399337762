export const startKeepAlive = () => {
    // Get the API base URL from environment variables or default to localhost
    const internalUrl = process.env.REACT_APP_API_BASE_URL;
    const url = internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';

    fetch(`${url}/api/ping`)
        .then(() => console.log('Keep-alive ping sent'))
        .catch((error) => console.log('Ping failed:', error));

};
