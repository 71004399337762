import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ContactSupport } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuContent from './MenuContent';
import { useTranslation } from 'react-i18next';


const drawerWidth = 70;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: open ? drawerWidth : drawerWidth * 3.4,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : drawerWidth * 3.4,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
    backgroundColor: 'transparent', // Transparent background
    backdropFilter: 'blur(10px)', // Optional: Add a blur effect
    borderRight: 'none', // Remove border if needed for a cleaner look
  },
}));


export default function SideBar({open, handleDrawerToggle, demo}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <MenuContent demo={demo}/>
      <Divider />
      <Stack direction="row"  sx={{ p: 2, gap: 0, alignItems: 'center', ml: -2, width: '100%', height: 36, color: '#1c4ed8' }}>
        <Link to={'/contact'} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItemButton 
            selected={selectedIndex}
            onClick={() => handleListItemClick(selectedIndex)}
          >
            <ListItemIcon sx={{ justifyContent: 'left', color: '#1c4ed8' }}>
              <ContactSupport sx={{ fontSize: 32 }}/>
            </ListItemIcon>
            <ListItemText primary={t('Contact Us')} />
          </ListItemButton>
        </Link>
      </Stack>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mr: 2.5 }}>
        <IconButton onClick={handleDrawerToggle} sx={{color:'#1c4ed8'}}>
          {open ? <ChevronRightIcon sx={{ fontSize: 32 }}/> : <ChevronLeftIcon sx={{ fontSize: 32, ml: 2 }}/> }
        </IconButton>
      </Box>
    </Drawer>
  );
}
