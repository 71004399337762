import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import pl from './i18n/pl.json';

// Helper function to normalize language code
const normalizeLanguage = (lng) => {
  const mapping = {
    'en-GB': 'en',
    'en-US': 'en',
    'pl-PL': 'pl'
  };
  return mapping[lng] || lng.split('-')[0];
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      pl: { translation: pl }
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'pl'],
    load: 'languageOnly',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'htmlTag', 'navigator'],
      lookupQuerystring: 'lang',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 60 * 24 * 365,
      cookieDomain: window.location.hostname,
    },
  });

// Force normalize language immediately after detection
i18n.on('initialized', () => {
  const normalizedLang = normalizeLanguage(i18n.language);
  if (normalizedLang !== i18n.language) {
    i18n.changeLanguage(normalizedLang);
  }
  console.log('i18next initialized successfully');
});

// Also normalize on language changes
i18n.on('languageChanged', (lng) => {
  const normalizedLang = normalizeLanguage(lng);
  if (normalizedLang !== lng) {
    i18n.changeLanguage(normalizedLang);
  }
});

i18n.on('failedLoading', (lng, ns, msg) => {
  console.error(`i18next failed loading: ${lng}`, msg);
});

export default i18n;

