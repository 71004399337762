import React from 'react';
import { Zap, Clock, TrendingUp } from 'lucide-react';
import { Card, CardContent, Typography, Grid, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const StatSection = () => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const stats = [
    { 
      icon: TrendingUp, 
      value: '99%', 
      label: t('Accuracy Rate'),
      color: '#1c4ed8' 
    },
    { 
      icon: Clock, 
      value: '70%', 
      label: t('Time Saved'), 
      color: '#1c4ed8' 
    },
    { 
      icon: Zap, 
      value: '5x', 
      label: t('Faster Processing'), 
      color: '#1c4ed8' 
    }
  ];

  return (
    <Box sx={{mb: 5}}>
      <Typography 
        variant="h4" 
        component="h3"
        sx={{ 
          my: 10,
          textAlign: 'center', 
          mb: 5,
          fontWeight: 500,
          color: theme.palette.text.primary
        }}
      >
        {t('With YourDraft You Get')}
      </Typography>
      <Grid container spacing={4} >
      {stats.map((stat, index) => (
          <Grid item xs={12} md={4} key={index}>
          <Card
              sx={{
              textAlign: 'center',
              borderRadius: '20px',
              width: '100%',
              border: 0.5,
              '&:hover': { transform: 'scale(1.05) translateY(-10px)', transition: 'transform 0.3s', border: 1, 
                borderColor: '#1c4ed8', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" },
              }}
          >
              <CardContent 
              sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  p: 3 
              }}
              >
              <stat.icon 
                  size={42} 
                  strokeWidth={1.5} 
                  color={stat.color} 
              />
              <Typography 
                  variant="h4" 
                  sx={{ 
                  mt: 2, 
                  fontWeight: 600, 
                  color: theme.palette.text.primary 
                  }}
              >
                  {stat.value}
              </Typography>
              <Typography 
                  variant="subtitle1" 
                  color="text.secondary"
              >
                  {stat.label}
              </Typography>
              </CardContent>
          </Card>
          </Grid>
      ))}
      </Grid>
    </Box>
  )};