import React from 'react';
import { motion } from 'framer-motion';
import { 
  Box, 
  Card, 
  CardContent, 
  Grid, 
  Typography, 
  Button, 
  useTheme,
  useMediaQuery
} from '@mui/material';
import { LayoutDashboard, Shield, ChevronRight, Zap, Clock } from 'lucide-react';
import { useTranslation } from 'react-i18next';

// Animation variants for smoother transitions
const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2
    }
  }
};

const itemVariants = {
  initial: { 
    opacity: 0, 
    y: 20 
  },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut'
    }
  }
};

export const FeatureCard = () => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const features = [
    {
      icon: LayoutDashboard,
      title: t('Intelligent Dashboard'),
      description: t('Comprehensive overview of your invoice processing pipeline with real-time insights and analytics.'),
      demo: t('See live demo')
    },
    {
      icon: Zap,
      title: t('AI-Powered Automation'),
      description: t('Our advanced algorithms automatically extract and validate invoice data with high accuracy.'),
      demo: t('View AI in action')
    },
    {
      icon: Shield,
      title: t('Enterprise Security'),
      description: t('Bank-grade encryption and compliance with SOC 2, GDPR, and other major security standards.'),
      demo: t('Security features')
    },
    {
      icon: Clock,
      title: t('Rapid Processing'),
      description: t('Process thousands of invoices in minutes, not hours, with automated validation and routing.'),
      demo: t('Speed test')
    }
  ];

  return (
    <Box 
      component={motion.div}
      variants={containerVariants}
      initial="initial"
      animate="animate"
      sx={{ 
        py: 8, 
        backgroundColor: theme.palette.background.default 
      }}
    >
      <Typography 
        variant="h4" 
        component="h3"
        sx={{ 
          textAlign: 'center', 
          mb: 6,
          fontWeight: 700,
          color: theme.palette.text.primary
        }}
      >
        {t('How Will You Benefit')}
      </Typography>

      <Grid 
        container 
        spacing={4} 
        justifyContent="center"
      >
        {features.map((feature, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            lg={3} 
            key={index}
          >
            <Card
              component={motion.div}
              variants={itemVariants}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 3,
                boxShadow: theme.shadows[2],
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: theme.shadows[6],
                },
                '&:hover .hoverButton': {
                opacity: 1,
                },
              }}
            >
              <CardContent 
                sx={{ 
                  flexGrow: 1, 
                  textAlign: 'center',
                  p: 2
                }}
              >
                <Box 
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 2,
                    color: theme.palette.primary.main
                  }}
                >
                  <feature.icon 
                    size={isMobile ? 36 : 48} 
                    strokeWidth={1.5} 
                  />
                </Box>

                <Typography 
                  variant="h6" 
                  component="h3"
                  sx={{ 
                    mb: 2,
                    color: theme.palette.text.primary,
                    fontWeight: 600
                  }}
                >
                  {feature.title}
                </Typography>

                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    mb: 2,
                    flexGrow: 1 
                  }}
                >
                  {feature.description}
                </Typography>

                <Button
                endIcon={<ChevronRight />}
                className="hoverButton"
                sx={{
                color: theme.palette.primary.main,
                opacity: 0,
                transition: 'opacity 0.3s',
                }}
                >
                {feature.demo}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
