import React from 'react';
import { Box, Container, Typography, TextField, Button } from '@mui/material';
import { Navbar } from '../../components/Navbar/Navbar';
import "./style.css";

export const ContactUs = () => {
    return (
        <Box 
            width="100%" 
            sx={{
                backgroundImage: "url('https://flowbite.s3.amazonaws.com/blocks/e-commerce/girl-shopping-list.svg')", 
                backgroundSize: 'small', 
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                bgcolor: "rgba(255, 255, 255, 0.85)", // Slight white overlay for readability
                backdropFilter: "blur(25px)", // Optional blur effect
                zIndex: -1,
            }}
        >  
            <Navbar sideBarOpen={true}/>
            <Container maxWidth="md" sx={{ pt: 5 }}>
                <Box sx={{ textAlign: 'center', mb: 5, mt: 10 }} >
                    <Typography variant="h2" gutterBottom>
                        Contact Us!
                    </Typography>
                    <Typography variant="h5" color="textSecondary" sx={{ mb: 2 }}>
                        Please contact us and one of the founders will reach out with demo session! 
                    </Typography>
                    <Typography variant="h6" color="textSecondary" sx={{ mb: 4 }}>
                         Let us know about any feedback.
                    </Typography>
                </Box>
                <form>
                    <Box sx={{ mb: 4 }} bgcolor="white">
                        <TextField
                            fullWidth
                            label="Your email"
                            variant="outlined"
                            type="email"
                            required
                            size='small'
                        />
                    </Box>
                    <Box sx={{ mb: 4 }} bgcolor="white">
                        <TextField
                            fullWidth
                            label="Subject"
                            variant="outlined"
                            size='small'
                            required
                        />
                    </Box>
                    <Box sx={{ mb: 4 }} bgcolor="white">
                        <TextField
                            fullWidth
                            label="Your message"
                            variant="outlined"
                            multiline
                            rows={6}
                            required
                        />
                    </Box>
                    <Box textAlign="center">
                        <Button
                            variant="contained"
                            className="button"
                            color="primary"
                            type="submit"
                            sx={{ mr: 2, borderRadius: '50px' }}
                        >
                            Send Message
                        </Button>
                    </Box>
                </form>
            </Container>
        </Box>
    );
};


