import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Chip } from '@mui/material';

// Helper to render status as a Chip
const renderStatusChip = (status) => {
  const colorMap = {
    Approved: 'success',
    'Pending Review': 'warning',
    'In Review': 'blue', // Custom color
  };

  // Define a fallback style for custom colors
  const chipStyle = colorMap[status] === 'blue'
    ? { backgroundColor: '#2196f3', color: 'white' }
    : {};

  return (
    <Chip
      label={status}
      color={colorMap[status] !== 'blue' ? colorMap[status] : undefined}
      size="small"
      sx={chipStyle} // Apply the custom style if needed
    />
  );
};


// InvoiceDetailTable columns
const columns = [
  { field: 'id', headerName: 'ID', flex: 0.5, minWidth: 80 },
  { field: 'customer', headerName: 'Customer', flex: 1, minWidth: 195 },
  { field: 'vendor', headerName: 'Vendor', flex: 1, minWidth: 195 },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 0.7,
    minWidth: 120,
    type: 'number',
    valueFormatter: (params) =>
      params.value !== undefined ? `$${params.value.toFixed(2)}` : '$0.00',
  },
  {
    field: 'submittedAt',
    headerName: 'Submitted',
    flex: 1,
    minWidth: 170,
    type: 'date',
    valueFormatter: (params) => {
      const date = params ? new Date(params) : null;
      return date ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : 'N/A';
    }
    
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    minWidth: 170,
    valueFormatter: (params) => {
      const date = params ? new Date(params) : null;
      return date ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : 'N/A';
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.6,
    minWidth: 170,
    renderCell: (params) => renderStatusChip(params.value),
  }
];

export default function CustomizedInvoiceTable({ data }) {
  return (
    <DataGrid
      autoHeight
      rows={data}
      columns={columns}
      checkboxSelection
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pageSizeOptions={[10, 25, 50]}
      density="compact"
      sx={{
        borderRadius: '20px', 
        '& .MuiDataGrid-root': {
          backgroundColor: '#ffffff', // Overall table background
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: '#f5f5f5', // Slightly gray footer
          color: '#000000', // Black text for contrast
          borderBottom: '1px solid #dcdcdc', // Add a subtle border below headers
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: '#f5f5f5', // Slightly gray footer
          borderTop: '1px solid #ddd', // Subtle border for separation
        },
        '& .MuiDataGrid-row.odd': {
          backgroundColor: '#ffffff', // Ensure rows stay white
        },
      }}
      slotProps={{
        filterPanel: {
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
            },
          },
        },
      }}
    />
  );
}

