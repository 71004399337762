import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MenuContent({ demo }) {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const mainListItems = [
    { text: t('Home'), icon: <HomeRoundedIcon sx={{ fontSize: 28 }}/>, link: '/' },
    { text: t('My Dashboard'), icon: <DashboardCustomizeRoundedIcon sx={{ fontSize: 28 }}/>, link: '/dashboard' },
    { text: t('Analytics'), icon: <AnalyticsRoundedIcon sx={{ fontSize: 28 }}/>, link: '/analytics' },
  ];
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between', mt: 13 }}>
      <List dense>
        {mainListItems.map((item, index) => (
          !item.link.includes(location.pathname) && (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton 
                selected={selectedIndex === index} 
                onClick={() => handleListItemClick(index)}
              >
                <ListItemIcon sx={{ justifyContent: 'left', color: '#1c4ed8' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </Link>
          </ListItem>
          )
        ))}
      </List>
    </Stack>
  );
}
