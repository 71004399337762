import React from 'react';
import { Grid } from '@mui/material';
import HighlightedCard from './HighlightedCard';
import StatCard from './StatCard';

const InvoiceMetricsCards = ({ data }) => {
  const totalInvoices = data.length;

  // Calculate the total invoice amount (sum of 'amount' fields)
  const totalInvoiceAmount = data
    .filter(invoice => invoice.amount)  // Filter out invoices without amount
    .reduce((sum, invoice) => sum + invoice.amount, 0);

  // Calculate total tax (assuming tax is 25% of the total amount)
  const totalInvoiceTax = totalInvoiceAmount * 0.25;

  // Example trend data (you can calculate this dynamically if needed)
  const trendData = data.map(invoice => invoice.amount);
  
  return (
    <Grid container spacing={2} justifyContent="center">
      {/* Total Invoices */}
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Total Invoices"
          value={totalInvoices.toLocaleString()}
          interval="Last Month"
          trend="neutral" // Replace with "up", "down", or "neutral" based on trend logic
          data={trendData} // This will show trends for invoice amounts
        />
      </Grid>

      {/* Total Amount */}
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Total Amount"
          value={totalInvoiceAmount.toLocaleString()}
          interval="Last Month"
          trend="up" // Replace with "up", "down", or "neutral" based on trend logic
          data={trendData} // This will show trends for invoice amounts
        />
      </Grid>

      {/* Total Tax */}
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Total Tax"
          value={totalInvoiceTax.toLocaleString()}
          interval="Last Month"
          trend="neutral" // Replace with "up", "down", or "neutral" based on trend logic
          data={trendData} // This will show trends for invoice amounts
        />
      </Grid>

      {/* Highlighted Card */}
      <Grid item xs={12} sm={6} md={3}>
        <HighlightedCard />
      </Grid>
    </Grid>
  );
};

export default InvoiceMetricsCards;



