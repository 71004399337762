// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Set base font family for the entire application */
* {
  font-family: 'Funnel Display';
}

/* Light weight (300) for regular text */
p, 
span,
div,
.body-text,
.info-block {
  font-weight: 400;
}

/* Medium weight (500) for interactive and heading elements */
button,
.button,
h1, h2, h3, h4, h5, h6,
.list-heading,
.headline {
  font-weight: 500;
}


/* Loading spinner styles */
.app-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  font-family: 'Funnel Display';
  color: #333;
}

.app-loading h1, .app-loading h2 {
  margin-bottom: 20px;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #1c4ed8;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Ensure content is visible */
/* #root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
} */

/* Ensure the app container takes up space */
/* .App {
  flex: 1;
  display: flex;
  flex-direction: column;
} */

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,oDAAoD;AACpD;EACE,6BAA6B;AAC/B;;AAEA,wCAAwC;AACxC;;;;;EAKE,gBAAgB;AAClB;;AAEA,6DAA6D;AAC7D;;;;;EAKE,gBAAgB;AAClB;;;AAGA,2BAA2B;AAC3B;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,WAAW;EACX,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;EAC1B,kCAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA,8BAA8B;AAC9B;;;;GAIG;;AAEH,4CAA4C;AAC5C;;;;GAIG","sourcesContent":["/* Set base font family for the entire application */\n* {\n  font-family: 'Funnel Display';\n}\n\n/* Light weight (300) for regular text */\np, \nspan,\ndiv,\n.body-text,\n.info-block {\n  font-weight: 400;\n}\n\n/* Medium weight (500) for interactive and heading elements */\nbutton,\n.button,\nh1, h2, h3, h4, h5, h6,\n.list-heading,\n.headline {\n  font-weight: 500;\n}\n\n\n/* Loading spinner styles */\n.app-loading {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  width: 100%;\n  font-family: 'Funnel Display';\n  color: #333;\n}\n\n.app-loading h1, .app-loading h2 {\n  margin-bottom: 20px;\n}\n\n.loading-spinner {\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  width: 36px;\n  height: 36px;\n  border-radius: 50%;\n  border-left-color: #1c4ed8;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n/* Ensure content is visible */\n/* #root {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n} */\n\n/* Ensure the app container takes up space */\n/* .App {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
