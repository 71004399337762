import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Button, IconButton, Typography, Grid, Container, List, ListItem, Tabs, Tab } from "@mui/material";
import { CircularProgress, ListItemIcon, ListItemText } from "@mui/material";
import {Download as DownloadIcon} from "@mui/icons-material";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Navbar } from "../../components/Navbar/Navbar";
import { DocumentContent } from "../../components/DocumentContent/DocumentContent";
import { UploadFrame } from "../../components/UploadFrame/UploadFrame";
import "./style.css";
import { ItemList } from "../../components/ItemList/Itemlist";
import { QueryFrame } from "../../components/QueryFrame/QueryFrame";
import { DownloadFrame } from "../../components/DownloadFrame/DownloadFrame";
import { SaveFrame } from "../../components/SaveFrame/SaveFrame";
import { motion } from "framer-motion";
import SideBar from "../../components/SideBar/SideBar";
import Chatbot from "../../components/ChatbotFrame/Chatbot";
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';


const transformToFields = (data) => {
  return Object.keys(data).map(key => {
    const value = data[key];

    // Check if the value is an object
    if (typeof value === 'object' && value !== null) {
      // Convert the object to a string representation or handle it as needed
      return {
        key: key,
        value: JSON.stringify(value) // You can customize this part based on your needs
      };
    }

    // Otherwise return the key-value pair as is
    return {
      key: key,
      value: value
    };
  });
};


function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

export const InvoiceDetailView = () => {
  const demo = !localStorage.getItem("authTokens");
  const company = localStorage.getItem('company') || 'Demo Company';
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Get the list of invoice IDs and file paths from the query parameters
  const invoiceIds = useMemo(() => {
      return queryParams.get('invoiceIds')?.split(',') || [];
  }, [queryParams]); // Dependency array includes queryParams

  const filePaths = useMemo(() => {
      return queryParams.get('filepaths')?.split(',') || [];
  }, [queryParams]); // Dependency array includes queryParams

  const addedBy = useMemo(() => {
    const addedByParam = queryParams.get('addedBy');
    return addedByParam !== 'undefined' ? addedByParam.split(',') : 'Demo User';
  }, [queryParams]); // Dependency array includes queryParams
  

  const addedOn = useMemo(() => {
    return queryParams.get('addedOn')?.split(',') || [];
  }, [queryParams]); // Dependency array includes queryParams
  
  const handleDownloadClick = useCallback(() => setShowDownloadFrame(true), []);
  // Create state for current invoiceId
  const [currentInvoiceId, setCurrentInvoiceId] = useState(invoiceIds[0]);
  
  const fileInvoiceMapping = useMemo(() => filePaths.map((filePath, index) => ({
    filePath,
    invoiceId: invoiceIds[index]
  })), [filePaths, invoiceIds]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedDocument, setSelectedDocument] = useState(filePaths[0]);
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [showQueryFrame, setShowQueryFrame] = useState(false);
  const [fields, setFields] = useState([]);
  const [initFields, setInitFields] = useState();
  const [modifiedFields, setModifiedFields] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [showDownloadFrame, setShowDownloadFrame] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [showSaveFrame, setShowSaveFrame] = useState(false);
  const handleUploadClick = () => setShowUploadFrame(true);
  const handleCloseUploadFrame = () => setShowUploadFrame(false);
  const handleQueryFrame = () => setShowQueryFrame(true);
  const axios = useAxios();

  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const handleCloseQueryFrame = async() => {
    await fetchItems();
    setShowQueryFrame(false);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseDownloadFrame = useCallback(() => {
    setShowDownloadFrame(false);
  }, []);

  const fetchItems = useCallback(async () => {
    if (!currentInvoiceId) return;
    try {
      setLoading(true);
      if (demo) {
        const response = await fetch(`${url}/api/invoices/${currentInvoiceId}/items`);
        
        if (!response.ok) {
          throw new Error(`Failed to fetch. Status: ${response.status}`);
        }
    
        const data = await response.json();
        
        if (data.message === "Success") {
          setInitFields(data.data)
          const transformedFields = transformToFields(data.data);
          setFields(transformedFields);
        } else {
          console.error("Unexpected response message:", data.message);
        }
      } else {
        const response = await axios.get(`${url}/api/invoices/${currentInvoiceId}/items`);
      if (response.data.message === "Success") {
        setInitFields(response.data.data)
        const transformedFields = transformToFields(response.data.data);
        setFields(transformedFields);
      } else {
        console.error("Unexpected response message:", response.data.message);
      }
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setFields([]); // Reset fields on error
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvoiceId, demo, url]);


  const handleChanges = () => {
    if (changesMade) {
      setShowSaveFrame(true); // Show SaveFrame if there are changes
    } else {
      navigate('/dashboard'); // If no changes, go directly to the dashboard
    }
  };
  
  // Triggered when user wants to navigate away (e.g., to dashboard)
  const handleGoDashboard = () => {
    handleChanges();
  };

  const handleClose = () => {
    setShowSaveFrame(false);
  };
  
  // When SaveFrame is closed (without saving), discard changes and navigate
  const handleCloseSaveFrame = () => {
    setShowSaveFrame(false);
    navigate('/dashboard');
  };
  
  // When SaveFrame is confirmed and changes are saved, proceed to update and navigate
  const handleSaveAndApprove = useCallback(() => {
    setShowSaveFrame(false);
      updateStatus(currentInvoiceId)
        .then(() => {
          navigate('/dashboard'); // Navigate to dashboard after successful update
        })
        .catch(error => {
          console.error('Update error:', error);
        });
    // eslint-disable-next-line
  }, []);
  
  const handleApprove = () => {
    if (changesMade) {
      setShowSaveFrame(true); // If changes are made, show SaveFrame first
    } else {
      updateStatus(currentInvoiceId) // If no changes, proceed with approval directly
        .then(() => {
          navigate('/dashboard');
        })
        .catch(error => {
          console.error('Navigation error:', error);
        });
    }
  };
  
  useEffect(() => {
    fetchItems();
  }, [fetchItems, currentInvoiceId, handleSaveAndApprove]);

  const updateStatus = async (invoiceId) => {
    const status = { "status": "APPROVED" };
    try {
      if (demo) {
        const response = await fetch(`${url}/api/invoices/${invoiceId}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(status)
        });
    
        if (!response.ok) {
          throw new Error('Failed to update status:', response.statusText);
        }
      } else {
        await axios.post(`${url}/api/invoices/${invoiceId}/`, 
          status,
          {
          headers: {
            'Content-Type': 'application/json'
          },
        });
      }
    } catch (error) {
      console.error('Update error:', error);
    }
  };

  const handleRerun = async () => {
    setLoading(true);
    try {
      if (demo) {
        const response = await fetch(`${url}/api/invoices/${currentInvoiceId}/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ invoice_id: currentInvoiceId }),
        });
    
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message);
        }
        
        await fetchItems();
      } else {
        await axios.put(`${url}/api/invoices/${currentInvoiceId}/`, 
          { invoice_id: currentInvoiceId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        try {
          await fetchItems();
        } catch (fetchError) {
          console.error("Error fetching items after rerun:", fetchError);
        }
      }
    } catch (error) {
      console.error("Error rerunning invoice:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentChange = (newFilePath) => {
    if (selectedDocument === newFilePath) return;
    else {
    const mapping = fileInvoiceMapping.find(item => item.filePath === newFilePath);
    if (mapping) {
      setFields([]);
      setCurrentInvoiceId(mapping.invoiceId);
      setSelectedDocument(newFilePath);
      setPageNumber(1);
    }
    }
  };

  const goToPreviousPage = () => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  };

  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };


  return (
    <Box width="100%" bgcolor="white" 
    sx={{
      backgroundImage:
        'radial-gradient(ellipse 80% 80% at 50% -20%, hsl(210, 100%, 90%), transparent)',
      '&.dark': {
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
      },
    }}>
      <>
        <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={true} />
        <Navbar sideBarOpen={openSideBar} />
        {showUploadFrame && <UploadFrame onClose={handleCloseUploadFrame} />}
        {showSaveFrame && <SaveFrame 
                            onDiscard={handleCloseSaveFrame}
                            onSave={handleSaveAndApprove}
                            onClose={handleClose}
                            fields={initFields}
                            modifiedFields={modifiedFields}
                            url={url}
                            invoiceId={currentInvoiceId} />}
        {showQueryFrame && (
          <QueryFrame 
            onClose={handleCloseQueryFrame}
            url={url}
            invoiceId={currentInvoiceId}
          />
        )}

        <Container maxWidth="500px" sx={{mt: 12 }}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IconButton 
                color="primary" 
                onClick={handleGoDashboard}
                sx={{ color: '#1c4ed8', border: '1px solid #f5f5f5' }}
              >
                <ArrowBackIcon />
              </IconButton>
              <List sx={{
                overflow: 'auto',
                height: 'auto',
                backgroundColor: 'white',
                borderRadius: '20px',
                color: '#1c4ed8',
                border: 0.1,
              }}>
                {filePaths.map((doc, index) => (
                  <ListItem 
                    button 
                    selected={selectedDocument === doc}
                    key={index} 
                    onClick={() => handleDocumentChange(doc)}
                    sx={{
                      backgroundColor: selectedDocument === doc && filePaths.length > 1 ? '#f5f5f5' : 'white',
                      color: '#1c4ed8',
                      borderRadius: '20px',
                      justifyContent: 'center', 
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex', width: '10vh', cursor: 'pointer' }}>
                      <Box 
                        sx={{
                          width: '10vh', 
                          height: '13vh', 
                          display: 'flex', 
                          backgroundColor: 'white',
                          borderColor: '#1c4ed8',
                          border: 0.4,
                          alignItems: 'center', 
                        }}
                      >
                        <DocumentContent 
                          path={doc}
                          pageNumber={1}
                          scale={0.13}
                          onDocumentLoadSuccess={onDocumentLoadSuccess}
                        />
                      </Box> 
                      <Typography sx={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '90px', // adjust the width as needed
                      }}>{doc.split('/').pop()}</Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
              <IconButton onClick={handleUploadClick}>
                <AddIcon sx={{color: '#1c4ed8'}}/>
              </IconButton>
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Box
                sx={{
                  width: '98%',
                  height: '90vh',
                  flexDirection: 'column', // Stack PDF and buttons vertically
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden', // Prevents overflow
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                  <Typography variant="h5" sx={{color:'black', p: 1}}>
                    {fields.find(field => field.key === 'invoice_name')?.value.split('/').pop() || t('No Invoice Name')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', p: 0.7, mb: 0.5, ml: 0.5}}>
                <Typography variant="subtitle2" sx={{ color: 'black', mr: '30px' }}>
                  {t("Added by")}: <span style={{ color: '#1c4ed8' }}>{addedBy || 'Demo user'}</span>
                </Typography>
                <Typography variant="subtitle2" sx={{ color: 'black', mr: '30px' }}>
                  {t("Company")}: <span style={{ color: '#1c4ed8' }}>{company}</span>
                </Typography>
                <Typography variant="subtitle2" sx={{ color: 'black', mr: '30px' }}>
                  {t("Added on")}: <span style={{ color: '#1c4ed8' }}>{new Date(addedOn).toLocaleDateString()}</span>
                </Typography>
                </Box>
                {/* Header field */}
                <Box sx={{ display: 'flex', width: '95%', justifyContent: 'space-between', alignItems: 'center',
                  marginBottom:0.5,
                 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                    <Button
                      variant="outlined" 
                      size="small" 
                      startIcon={<AddBoxOutlinedIcon sx={{color:'#1c4ed8'}} />}
                      sx={{ marginLeft: 1, borderColor: '#1c4ed8', color: 'black', borderRadius: '50px',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: '#1c4ed8',
                          color: 'white',
                        },
                      }}
                      onClick={handleQueryFrame}
                    >
                      {t("Add custom field")}
                    </Button>
                    <Button
                      variant="outlined" 
                      size="small" 
                      startIcon={<FastForwardOutlinedIcon sx={{color:'#1c4ed8'}} />}
                      sx={{ borderColor: '#1c4ed8',marginLeft: 1, color: 'black', backgroundColor: 'white', borderRadius: '50px',
                        '&:hover': {
                          backgroundColor: '#1c4ed8',
                          color: 'white',
                        },
                      }}
                      onClick={() => handleRerun()}
                    >
                      {t("Rerun")}
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" startIcon={<DoneOutlinedIcon sx={{color:'white'}} />} size="small" 
                    sx={{borderRadius: '50px', color:"white", backgroundColor: '#1c4ed8'}} 
                    onClick={handleApprove}>
                        {t("Approve")}
                    </Button>
                    <Button
                      variant="outlined" 
                      onClick={handleDownloadClick}
                      size="small" 
                      sx={{ 
                        marginLeft: 1,
                        marginRight: 1,
                        borderColor: '#1c4ed8', 
                        color: 'black', 
                        backgroundColor: 'white',
                        width: '32px',
                        minWidth: '32px',
                        padding: '4px',
                        '& .MuiButton-startIcon': {  // Remove default icon spacing
                          margin: 0,
                          padding: 0,
                        },
                        '&:hover': {
                          backgroundColor: '#1c4ed8',
                          color: 'white',
                        },
                      }}
                    >
                      <DownloadIcon sx={{ fontSize: 20, color: '#1c4ed8', '&:hover': {
                          color: 'white',
                        },}} />
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '95%', justifyContent: 'center', alignItems: 'center', mb: -0.5
                 }}>
                  <IconButton
                    variant="contained"
                    onClick={goToPreviousPage}
                    disabled={pageNumber <= 1}
                    sx={{ borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                  >
                    <ChevronLeftIcon/>
                  </IconButton>
                  <Typography variant="body1" sx={{ justifyContent: 'center', minWidth: 43 }}>
                    {t('pageInfo', { pageNumber, numPages })}
                  </Typography>
                  <IconButton
                    variant="contained"
                    onClick={goToNextPage}
                    disabled={pageNumber >= numPages}
                    sx={{ borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
                <Box 
                  sx={{
                    width: '95%', 
                    height: '72vh',
                    display: 'flex', 
                    backgroundColor: '#d3d3d3',
                    borderRadius: '25px',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    overflow: 'auto',
                  }}
                >
                  <DocumentContent 
                    path={selectedDocument} // Pass the document path
                    pageNumber={pageNumber} 
                    scale={0.74} 
                    onDocumentLoadSuccess={onDocumentLoadSuccess} />
                </Box>
              </Box>
            </Grid>
    
            <Grid item xs={openSideBar ? 4 : 3} sx={{ display: 'flex', flexDirection: 'column' }}>
              {/* Tabs */}
              <Box sx={{ borderColor: 'divider', mt: '75px' }} width='100%'>
                <Tabs 
                  value={tabValue} 
                  onChange={handleTabChange} 
                  aria-label="invoice tabs" 
                  variant="fullWidth"
                  sx={{ 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    backgroundColor: '#f7f7f7', 
                    borderRadius: '20px',
                    height: '40%',
                    '& .MuiTab-root.Mui-selected': {
                      backgroundColor: '#ffffff', // White background for the selected tab
                    }
                  }}
                >
                  <Tab 
                    label={t("Details")} 
                    icon={<ArticleOutlinedIcon />} 
                    iconPosition='start' 
                    sx={{ borderRadius: '50px' }} 
                  />
                  <Tab 
                    label={t("Ask Me Anything")} 
                    icon={<ChatBubbleOutlineOutlinedIcon />} 
                    iconPosition='start' 
                    sx={{ borderRadius: '50px' }} 
                  />
                </Tabs>
              </Box>

              {/* Fields Tab Content */}
              <TabPanel value={tabValue} index={0}>
                <Box 
                  sx={{ 
                    overflowY: 'auto',
                    backgroundColor: 'white',
                    maxHeight: '565px',
                    width: '100%',
           
                    mt: -2,
                    borderRadius: '20px',
                  }}
                >
                  {loading ? (
                    <motion.div
                      initial={{ opacity: 1, scale: 1 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.9 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        position: "fixed",
                        top: "28%",
                        left: "62%",
                        width: "450px",
                        height: "630px",
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                      }}
                    >
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <List style={{ marginTop: "16px" }}>
                          <ListItem>
                            <ListItemIcon>
                              <CircularProgress size={20} style={{ color: "#1c4ed8" }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary={t("Loading items..")} 
                              style={{ fontWeight: "bold", color: "#1c4ed8", marginTop: "16px" }}
                            />
                          </ListItem>
                        </List>
                      </Box>
                    </motion.div>
                  ) : (
                    <ItemList 
                      fields={fields} 
                      setChangesMade={setChangesMade}
                      setModifiedFields={setModifiedFields}
                    />
                  )}
                </Box>
              </TabPanel>

              {/* AI Assistant Tab Content */}
              <TabPanel value={tabValue} index={1}>
                <Box 
                  sx={{ 
                    overflowY: 'auto',
                    backgroundColor: 'white',
                    maxHeight: '600px',
                    width: '100%',
                    mt: -2,
                    borderRadius: '20px',
                  }}
                >
                  <Chatbot invoiceId={currentInvoiceId} />
                </Box>
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
        {showDownloadFrame && <DownloadFrame 
                                  onClose={handleCloseDownloadFrame} 
                                  checkedInvoiceIds={[currentInvoiceId]}
                                  url={url} />}
      </>
    </Box>
  );
};



