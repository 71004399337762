import React from 'react';
import { motion } from 'framer-motion';
import { 
  Box, 
  Typography, 
  Grid, 
} from '@mui/material';
import { LayoutDashboard, Rocket, ArrowRightLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

// Container variants for whole section
const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { 
      duration: 0.5, 
      ease: 'easeOut',
      staggerChildren: 0.15 
    }
  }
};

// Content slide-in variants
const contentVariants = {
  initial: { opacity: 0, x: -50 },
  animate: { 
    opacity: 1, 
    x: 0,
    transition: { duration: 0.6, ease: 'easeOut' }
  }
};

// Image variants with scaling and gradient overlay on hover
const imageVariants = {
  initial: { scale: 1 },
  hover: { 
    transition: { duration: 0.3, ease: 'easeOut' }
  }
};

// Overlay variants for a subtle gradient demonstration
const overlayVariants = {
  initial: { opacity: 0 },
  hover: { opacity: 1, transition: { duration: 0.3, ease: 'easeInOut' } }
};

// Icon variants with rotation and scale for that extra "pop"
const iconVariants = {
  initial: { rotate: 0, scale: 1 },
  hover: { 
    rotate: [0, -10, 10, 0],
    scale: 1.2,
    transition: { duration: 0.5, ease: 'easeInOut' }
  }
};

export const FeatureCard = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: Rocket,
      title: t('Forget about manual data entry'),
      description: t('Our intelligent tool automatically extracts and inputs invoice data, freeing up your time for more important tasks.'),
      image: '/01_compressed.mp4',
      imagePosition: 'left'
    },
    {
      icon: LayoutDashboard,
      title: t('Everything at a glance'),
      description: t('Get immediate, clear insights into all your document details through our intuitive dashboard panel.'),
      image: '/02_compressed.mp4',
      imagePosition: 'right'
    },
    {
      icon: ArrowRightLeft,
      title: t('Integrate with your accounting software'),
      description: t('Effortlessly connect our solution with your preferred accounting platform in just minutes, creating a seamless workflow.'),
      image: '/03_compressed.mp4',
      imagePosition: 'left'
    },
    // {
    //   icon: Bot,
    //   title: t('Personal Assistant'),
    //   description: t('Interact with an intelligent virtual assistant for instant answers and support on invoice-related queries, legal and accounting.'),
    //   demo: t('Chatbot Demo'),
    //   path: '/chatbot',
    //   image: 'chatbot.png',
    //   imagePosition: 'right'
    // }
  ];


  return (
    <Box 
      component={motion.div}
      variants={containerVariants}
      initial="initial"
      animate="animate"
      sx={{ 
        position: 'relative',
        py: 12,
        px: { xs: 2, sm: 4, md: 8 },
      }}
    >
      {features.map((feature, index) => (
        <Grid 
          container 
          spacing={20} 
          alignItems="center"
          key={index}
          sx={{ mb: index !== features.length - 1 ? 12 : 0 }}
        >
          {/* Image Section */}
          <Grid 
            item 
            xs={12} 
            md={6}
            sx={{ order: { md: feature.imagePosition === 'left' ? 1 : 2 } }}
          >
            <motion.div
              whileHover="hover"
              initial="initial"
              variants={imageVariants}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: { xs: 240, sm: 280, md: 400 },
                  borderRadius: "24px",
                  overflow: "hidden",
                  boxShadow: 'none',
                  border: 'none',
                  transition: 'transform 0.3s ease',
                }}
              >
                {feature.image.endsWith('.mp4') ? (
                  <Box
                    component="video"
                    autoPlay
                    muted
                    loop
                    playsInline
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      display: 'flex',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      border: 'none',
                      outline: 'none',
                    }}
                  >
                    <source src={feature.image} type="video/mp4" />
                    Your browser does not support the video tag.
                  </Box>
                ) : (
                  <Box
                    component="img"
                    src={feature.image}
                    alt={feature.title}
                    loading="lazy"
                    decoding="async"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                )}
                {/* Gradient overlay on hover */}
                <motion.div
                  variants={overlayVariants}
                  initial="initial"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(135deg, rgba(28,78,216,0.2) 0%, rgba(28,78,216,0.05) 100%)',
                    border: 'none',
                  }}
                />
                {!feature.image.endsWith('.mp4') && (
                  <link rel="preload" as="image" href={feature.image} />
                )}
              </Box>
            </motion.div>
          </Grid>

          {/* Content Section */}
          <Grid 
            item 
            xs={12} 
            md={6}
            sx={{ order: { md: feature.imagePosition === 'left' ? 2 : 1 } }}
          >
            <Box
              component={motion.div}
              variants={contentVariants}
            >
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 500,
                  mb: 3,
                  color: 'black',
                  lineHeight: 1.2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontFamily: '"Funnel Display"'
                }}
              >
                <motion.div
                  whileHover="hover"
                  initial="initial"
                  variants={iconVariants}
                  style={{ display: 'inline-block' }}
                >
                  <Box sx={{ 
                    borderRadius: '12px',
                    marginRight: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <feature.icon size={36} color='#1c4ed8' />
                  </Box>
                </motion.div>
                {feature.title}
              </Typography>

              <Typography 
                variant="body1"
                color="black"
                sx={{ 
                  mb: 4,
                  fontSize: '1.125rem',
                  lineHeight: 1.7,
                  maxWidth: '90%',
                  fontFamily: '"Funnel Display"'
                }}
              >
                {feature.description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};


