import React, { useState, useCallback } from 'react';
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import "./style.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingScreen } from "../../components/LoadingScreen/LoadingScreen";
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';

export const UploadFrame = ({ onClose }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const [numFiles, setNumFiles] = useState(0);
  const axios = useAxios();

  let url;

  if (internalUrl) {
    url = `https://${internalUrl}`;
  } else {
    url = 'http://127.0.0.1:8000';
  }
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false); 

  const handleUpload = async () => {
    if (files.length === 0) return;
    console.log('Upload started');
    setLoading(true); // Set loading to true when upload starts

    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
      console.log(file);
    });

    try {
      const result = await axios.post(`${url}/api/invoices/`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      console.log('Upload successful');
      onClose(result.data.processed_invoices);
      setFiles([]);
      setNumFiles(0);

    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setLoading(false); // Reset loading state when upload completes
    }
  };

  const handleDeleteFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const droppedFiles = Array.from(e.dataTransfer.files).filter(
      (file) => file.type === "application/pdf"
    );
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setNumFiles((prevFiles) => prevFiles.length + droppedFiles.length);
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = [...e.target.files];
    setFiles(selectedFiles);
    setNumFiles(setFiles.length);
  };

  const handleClose = () => {
    onClose([]);
  };

  return (
    <>
      {/* Backdrop */}
      <div className="upload-backdrop"></div>
  
      {/* Loading Screen */}
      {loading && (
        <div className="loading-overlay">
          <LoadingScreen numFiles={numFiles}/>
        </div>
      )}
  
      {/* Upload Frame */}
      <div className="upload-frame">
        <div className="content-wrapper">
          {/* Close Button */}
          <IconButton
            size="medium"
            sx={{
              position: "absolute",
              top: 18,
              right: 18,
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f0f4ff" },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
  
          {/* Upload Section */}
          <div className="upload-content" style={{ textAlign: "center", padding: "30px" }}>
            <h2
              style={{
                fontSize: "24px",
                fontWeight: "600",
                marginBottom: "10px",
                textAlign: "left", // Ensures left alignment
              }}
            >
              Upload invoice
            </h2>

            {/* Dropzone */}
            <label
              htmlFor="dropzone-file"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: files.length > 0 ? "flex-start" : "center",
                height: "220px",
                width: "400px",
                borderRadius: "12px",
                backgroundImage: 'radial-gradient(ellipse 80% 80% at 50% 120%, hsl(210, 100%, 90%), transparent)',
                color: "#1c4ed8",
                padding: "20px",
                overflowY: "auto",
                cursor: "pointer",
                position: "relative",
              }}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              {/* Show the default message if no files are uploaded */}
              {files.length === 0 && (
                <>
                  {<FileUploadOutlinedIcon color="#1c4ed8" fontSize='large' />}
                  <p style={{ fontSize: "16px", margin: "0", textWrap: 'wrap', width: '150px', 
                    marginTop: '20px'
                   }}>Click to upload or drag and drop files</p>
                </>
              )}

              {/* Display uploaded files */}
              {files.length > 0 && (
                <div style={{ width: "100%" }}>
                  {files.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px 10px",
                        borderRadius: "8px",
                        background: "#f5f5f5",
                        marginBottom: "5px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "80%",
                        }}
                      >
                        {file.name}
                      </span>
                      <IconButton
                        onClick={() => handleDeleteFile(index)}
                        size="small"
                        sx={{ color: "#ff1744" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}

              {/* Hidden file input */}
              <input
                id="dropzone-file"
                type="file"
                accept=".pdf"
                multiple
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </label>
  
            {/* Upload Button */}
            <Button
              onClick={handleUpload}
              startIcon={<FileUploadOutlinedIcon color="white" />}
              disabled={files.length === 0 || loading}
              variant="contained"
              sx={{
                padding: "10px 20px",
                fontSize: "16px",
                color: "white",
                backgroundColor: "#1c4ed8",
                borderRadius: "24px",
                mt:'40px',
                "&:hover": { backgroundColor: "#163bb7" },
              }}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
  
      <style jsx>{`
        .upload-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1000;
        }
        
        .loading-overlay {
          position: fixed;
          top: 50%;
          left: 50%;
          border-radius: 70px;
          transform: translate(-50%, -50%);
          width: 500px;
          height: 510px;
          background: white; 
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1100; 
          background-color: #ffffff;
        }
  
        .upload-frame {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 500px;
          background: white;
          border-radius: 30px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          z-index: 1050;
          overflow: hidden;
        }
      `}</style>
    </>
  );
  
};





