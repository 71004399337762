import React from 'react';
import { useContext } from "react";
import { Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../../utils/AuthContext";
import { useTranslation } from 'react-i18next';

const fadeIn = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

export const HeroSection = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleSignIn = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      navigate('/login'); 
    }
  };
  const handleGoInvoice = () => {
    navigate('/dashboard');
  };

  return (
    
    <Box
      component="section"
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        mb: -10,
        background: "radial-gradient(ellipse 90% 55% at 50% -20%, hsl(210, 100%, 90%), transparent)"
      }}
    >
      <Box sx={{ 
        mx: 'auto',
        py: 30,
        height: '100%',
        px: { xs: 3, sm: 6, lg: 8 },
        textAlign: 'center'
      }}>
        <motion.div {...fadeIn}>
          
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', sm: '3.75rem' },
              fontWeight: 700,
              letterSpacing: '-0.025em',
              lineHeight: 1.2,
              mb: 3,
              color: '#1d4ed8',
              fontFamily: '"Funnel Display"'
            }}
          >
            <Box component="span" sx={{ color: '#000', fontFamily: '"Funnel Display"' }}>{t("Transform")} </Box>
            <Box component="span" sx={{ color: '#000', fontFamily: '"Funnel Display"' }}>{t("your invoice processing")}</Box>
            <br />
            <Box 
              component="span" 
              sx={{ 
                color: '#1d4ed8',
                display: 'inline-block',
                fontFamily: '"Funnel Display"'
              }}
            >
              {t("with AI intelligence")}
            </Box>
          </Typography>

          <Typography
            sx={{
              fontSize: '1.25rem',
              color: '#000',
              mx: 'auto',
              mb: 4,
              fontFamily: '"Funnel Display"'
            }}
          >
            {t("Automate your workflow and gain actionable insights with our enterprise-grade platform.")}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleSignIn}
              sx={{ 
                textTransform: 'none',
                px: 4, 
                py: 1, 
                borderRadius: '9999px',
                bgcolor: '#1d4ed8',
                fontSize: '1rem',
                fontFamily: '"Funnel Display"',
                boxShadow: 'none',
                '&:hover': {
                        boxShadow: 'none'
                      },
              }}
            >
              {t("Try free for 30 days")} 🚀
            </Button>
            <Button
              variant="outlined"
              onClick={handleGoInvoice}
              sx={{ 
                textTransform: 'none',
                px: 4, 
                py: 1, 
                color: '#1d4ed8',
                borderRadius: '9999px',
                bgcolor: 'rgba(255,255,255,0.1)',
                backdropFilter: 'blur(4px)',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #1d4ed8',
                gap: 1,
                fontSize: '1rem',
                '&:hover': {
                        backgroundColor: 'white',
                        borderColor: '#1d4ed8'
                      },
                fontFamily: '"Funnel Display"'
              }}
            >
              {t("See how it works")}
            </Button>
          </Box>
        </motion.div>

        {/* Modern dashboard preview with subtle scale effect */}
        {/* <Box sx={{ position: 'relative', mt: 8, width: '100%', pb: 8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component={motion.img}
              whileHover={{ scale: 1.03 }}
              src="/page.avif"
              alt="Dashboard Analytics"
              sx={{
                width: { xs: '90%', sm: '80%', md: '80%' },
                height: 'auto',
                borderRadius: '2.5rem',
                boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)'
              }}
            />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};
