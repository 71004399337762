// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-form {
    max-width: 600px; /* Optional: Limit the width of the form */
    margin: auto; /* Center the form on the page */
    justify-content: center;
    align-items: center;
    padding: 70px;
    height: 40px;
  }

  .sign-up-form .text {
    width: 450px;
    background-color: white;
    height: fit-content;
  }

  .sign-up-form .btn {
    width: 370px;
    background-color: #1c4ed8;
    border-color: #1c4ed8;
  }`, "",{"version":3,"sources":["webpack://./src/components/SignupForm/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,0CAA0C;IAC5D,YAAY,EAAE,gCAAgC;IAC9C,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;EACvB","sourcesContent":[".sign-up-form {\n    max-width: 600px; /* Optional: Limit the width of the form */\n    margin: auto; /* Center the form on the page */\n    justify-content: center;\n    align-items: center;\n    padding: 70px;\n    height: 40px;\n  }\n\n  .sign-up-form .text {\n    width: 450px;\n    background-color: white;\n    height: fit-content;\n  }\n\n  .sign-up-form .btn {\n    width: 370px;\n    background-color: #1c4ed8;\n    border-color: #1c4ed8;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
