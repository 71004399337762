import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';

const InvoiceVendorAnalysis = ({ data }) => {
  const theme = useTheme();

  // Process data to aggregate invoice count and total amount per vendor
  const vendorAnalysis = data.reduce((result, invoice) => {
    if (!result[invoice.vendor]) {
      result[invoice.vendor] = { vendor: invoice.vendor, invoiceCount: 0, totalAmount: 0 };
    }
    result[invoice.vendor].invoiceCount++;
    result[invoice.vendor].totalAmount += invoice.amount;
    return result;
  }, {});

  // Prepare chart data: sort by invoice count and limit to top 5 vendors
  const chartData = Object.values(vendorAnalysis)
    .sort((a, b) => b.invoiceCount - a.invoiceCount)
    .slice(0, 5);

  // Display message if no vendor data is available
  if (chartData.length === 0) {
    return (
      <Typography variant="body1" align="center">
        No vendor data available.
      </Typography>
    );
  }

  return (
    <Card variant="outlined" sx={{ width: '615px', borderRadius: '20px' }}>
      <CardContent>
        <Typography component="h4" variant="subtitle2 bold" gutterBottom>
          Top Vendor Analysis
        </Typography>
        <Stack sx={{ justifyContent: 'space-between', mb: 2 }}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {chartData.reduce((sum, vendor) => sum + vendor.invoiceCount, 0)} Invoices
            </Typography>
            <Chip size="small" color="success" label="+X% (Last Month)" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Summary of invoice count and total amounts by top 5 vendors
          </Typography>
        </Stack>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            layout="vertical"
            margin={{ top: 20, right: 20, left: 40, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="vendor" type="category" width={100} />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="invoiceCount"
              name="Invoice Count"
              fill={(theme.vars || theme).palette.success.main}
              barSize={20}
            />
            <Bar
              dataKey="totalAmount"
              name="Total Amount ($)"
              fill={(theme.vars || theme).palette.success.light}
              opacity={0.9}
              barSize={20}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

InvoiceVendorAnalysis.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      vendor: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default InvoiceVendorAnalysis;


