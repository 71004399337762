import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

const InvoiceCustomerAnalysis = ({ data }) => {
  const theme = useTheme();

  // Process data to aggregate invoice count and total amount per customer
  const customerAnalysis = data.reduce((result, invoice) => {
    if (!result[invoice.customer]) {
      result[invoice.customer] = {
        customer: invoice.customer,
        invoiceCount: 0,
        totalAmount: 0,
      };
    }
    result[invoice.customer].invoiceCount++;
    result[invoice.customer].totalAmount += invoice.amount;
    return result;
  }, {});

  // Prepare chart data, sorting by invoice count and limiting to top 5 customers
  const chartData = Object.values(customerAnalysis)
    .sort((a, b) => b.invoiceCount - a.invoiceCount)
    .slice(0, 5);

  // Display message if no customer data is available
  if (chartData.length === 0) {
    return (
      <Typography variant="body1" align="center">
        No customer data available.
      </Typography>
    );
  }

  return (
    <Card variant="outlined" sx={{ width: '615px', borderRadius: '20px' }}>
      <CardContent>
      <Typography component="h4" variant="subtitle2 bold" gutterBottom>
        Top Customer Analysis
        </Typography>
        <Stack sx={{ justifyContent: 'space-between', mb: 2 }}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {chartData.reduce((sum, customer) => sum + customer.invoiceCount, 0)} Invoices
            </Typography>
            <Chip size="small" color="success" label="+X% (Last Month)" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Summary of invoice count and total amounts by customer
          </Typography>
        </Stack>
        <Box sx={{ width: '100%', height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="customer" type="category" width={120} />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="invoiceCount"
                fill={(theme.vars || theme).palette.primary.main}
                name="Invoice Count"
                barSize={20}
              />
              <Bar
                dataKey="totalAmount"
                fill={(theme.vars || theme).palette.primary.light}
                name="Total Amount ($)"
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InvoiceCustomerAnalysis;

