import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog, 
  DialogContent, 
  DialogActions, 
  FormControl, 
  useTheme,
  TextField,
  CircularProgress,
  DialogTitle,
  DialogContentText
} from "@mui/material";
import { InputLabel, Select, MenuItem } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "./useAxios";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Business Context for managing multi-business state
const BusinessContext = createContext({
  businesses: [],
  currentBusiness: null,
  switchBusiness: async () => {},
  addBusiness: async () => {},
  fetchBusinesses: async () => {},
});

export const BusinessProvider = ({ children }) => {
  const [businesses, setBusinesses] = useState([]);
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const demo = !localStorage.getItem("authTokens");
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const externalUrl = process.env.REACT_APP_EXTERNAL_URL;
  const port = process.env.REACT_APP_PORT;
  const url = internalUrl ? `http://internalUrl:${port}` : externalUrl ? `https://${externalUrl}` : 'http://127.0.0.1:8000';
  const axios = useAxios();

  const fetchBusinesses = async () => {
    if (!demo) {
        try {
            const response = await axios.get(`${url}/api/customer/companies`);
            setBusinesses(response.data.data.companies);
            setCurrentBusiness(response.data.data.current_company);
        } catch (error) {
            console.error('Failed to fetch companies', error);
        }
    }
  };

  useEffect(() => {
    fetchBusinesses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Switch between businesses
  const switchBusiness = async (companyName) => {

    if (demo) {
        try {
            const response = await fetch(`${url}/api/customer/company/switch/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify({ company_name: companyName })
            })
            const data = await response.json();
            if (data.message === "Success") {
                fetchBusinesses();
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.error('Failed to switch business', error);
            throw error;
        }
    } else {
        try {
            const response = await axios.patch(`${url}/api/customer/company/switch/`, 
                { company_name: companyName });
            if (response.data.message === "Success") {
                fetchBusinesses();
            }
        } catch (error) {
            console.error('Failed to switch business', error);
            throw error;
        }
    }
    fetchBusinesses();
  };

  // Add a new business
  const addBusiness = async (businessData) => {
    if (demo) {
        try {
            const response = await fetch(`${url}/api/customer/companies/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify(businessData)
            })
            const data = await response.json();
            if (data.message === "Success") {
                fetchBusinesses();
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.error('Failed to add business', error);
            throw error;
        }

    } else {
        try {
            const response = await axios.post(`${url}/api/customer/companies/`, businessData);
            if (response.data.message === "Success") {
                fetchBusinesses();
            }
        } catch (error) {
            console.error('Failed to add business', error);
            throw error;
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // useEffect(() => {
  //   fetchBusinesses();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [addBusiness])

  return (
    <BusinessContext.Provider 
      value={{ 
        businesses, 
        currentBusiness, 
        switchBusiness, 
        addBusiness,
        fetchBusinesses 
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

// PropTypes validation
BusinessProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// Custom hook for using business context
export const useBusinessContext = () => {
  const context = useContext(BusinessContext);
  if (!context) {
    throw new Error('useBusinessContext must be used within a BusinessProvider');
  }
  return context;
};


// Add Business Modal Component
export const AddBusinessModal = ({ isOpen, onClose, onAddBusiness, proSubscription }) => {
  const theme = useTheme();
  const demo = !localStorage.getItem("authTokens");
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [, setIndustry] = React.useState('');
  const [businessData, setBusinessData] = useState({
    company_name: '',
    company_address: '',
    industry: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleIndustryChange = (e) => {
    const value = e.target.value;
    setIndustry(value);
    setBusinessData((prev) => ({
      ...prev,
      industry: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (demo) {
      navigate('/login');
      return;
    }

    if (proSubscription) {
      setIsLoading(true);
      try {
        await onAddBusiness(businessData);
        onClose();
      } catch (error) {
        console.error('Failed to add business', error);
      }
      setIsLoading(false);
    } else {
      setShowUpgradeDialog(true);
    }
  };

  const UpgradeDialog = () => (
    <Dialog
      open={showUpgradeDialog}
      onClose={() => setShowUpgradeDialog(false)}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          padding: theme.spacing(1),
          maxWidth: '400px'
        }
      }}
    >
      <DialogTitle sx={{ 
        textAlign: 'center',
        fontWeight: 600,
        color: '#1c4ed8' 
      }}>
        {t('Upgrade Required')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          {t('To add additional businesses, please upgrade to Enterprise Pro subscription.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
        <Button
          onClick={() => {
            setShowUpgradeDialog(false);
            setBusinessData({
              company_name: '',
              company_address: '',
              industry: ''
            });
            onClose();
          }}
          variant="outlined"
          sx={{
            borderRadius: '50px',
            px: 3,
            color: '#1c4ed8',
            borderColor: '#1c4ed8'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={() => navigate('/pricing')}
          variant="contained"
          sx={{
            borderRadius: '50px',
            px: 3,
            backgroundColor: '#1c4ed8',
            color: 'white'
          }}
        >
          {t('View Plans')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (!isOpen) return null;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="add-business-dialog-title"
        aria-describedby="add-business-dialog-description"
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '24px',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
            position: 'relative'
          }
        }}
      >
        <DialogContent sx={{ pt: theme.spacing(4), pb: theme.spacing(2) }}>
          <IconButton
            size="large"
            sx={{
              position: 'absolute',
              top: 18,
              right: 18,
              backgroundColor: 'white',
              '&:hover': { backgroundColor: '#f0f4ff' }
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <Typography
              variant="h5"
              id="add-business-dialog-title"
              sx={{ fontWeight: 600, color: theme.palette.text.primary, mb: 1 }}
            >
              {t('Add New Business')}
            </Typography>

            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label={t('Business Name')}
                  name="company_name"
                  value={businessData.company_name}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: '16px'
                    }
                  }}
                  required
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label={t('Address')}
                  name="company_address"
                  value={businessData.company_address}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: '16px'
                    }
                  }}
                  required
                />
              </FormControl>

              <FormControl
                fullWidth
                sx={{ position: 'relative' }}
              >
                <InputLabel
                  id="industry-label"
                  sx={{
                    backgroundColor: 'white',
                    padding: '0 4px',
                    '&.Mui-focused': {
                      backgroundColor: 'white',
                    },
                  }}
                >
                  {t('Industry')}
                </InputLabel>
                <Select
                  labelId="industry-label"
                  id="industry"
                  value={businessData.industry}
                  onChange={handleIndustryChange}
                  sx={{
                    borderRadius: '16px',
                    '& fieldset': {
                      transition: 'all 0.3s ease-in-out',
                      '& legend': {
                        width: 0,
                      },
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                      '& legend': {
                        maxWidth: '100px',
                      },
                    },
                  }}
                >
                  <MenuItem value="advertising_marketing">{t("Advertising & Marketing")}</MenuItem>
                  <MenuItem value="agriculture_food">{t("Agriculture & Food Production")}</MenuItem>
                  <MenuItem value="arts_culture">{t("Arts & Culture")}</MenuItem>
                  <MenuItem value="automotive">{t("Automotive")}</MenuItem>
                  <MenuItem value="craftsmanship">{t("Craftsmanship")}</MenuItem>
                  <MenuItem value="design">{t("Design")}</MenuItem>
                  <MenuItem value="education">{t("Education")}</MenuItem>
                  <MenuItem value="energy_utilities">{t("Energy & Utilities")}</MenuItem>
                  <MenuItem value="entertainment_media">{t("Entertainment & Media")}</MenuItem>
                  <MenuItem value="finance">{t("Finance")}</MenuItem>
                  <MenuItem value="food_beverage">{t("Food & Beverage")}</MenuItem>
                  <MenuItem value="healthcare">{t("Healthcare")}</MenuItem>
                  <MenuItem value="home_lifestyle">{t("Home & Lifestyle")}</MenuItem>
                  <MenuItem value="logistics_transportation">{t("Logistics & Transportation")}</MenuItem>
                  <MenuItem value="luxury_retail">{t("Luxury Retail")}</MenuItem>
                  <MenuItem value="manufacturing_industry">{t("Manufacturing & Industry")}</MenuItem>
                  <MenuItem value="nonprofit_social">{t("Non-Profit & Social Impact")}</MenuItem>
                  <MenuItem value="personal_services">{t("Personal Services")}</MenuItem>
                  <MenuItem value="real_estate">{t("Real Estate")}</MenuItem>
                  <MenuItem value="retail">{t("Retail")}</MenuItem>
                  <MenuItem value="science_development">{t("Science & Development")}</MenuItem>
                  <MenuItem value="security">{t("Security")}</MenuItem>
                  <MenuItem value="sport_recreation">{t("Sport & Recreation")}</MenuItem>
                  <MenuItem value="sustainability_environment">{t("Sustainability & Environment")}</MenuItem>
                  <MenuItem value="technology">{t("Technology")}</MenuItem>
                  <MenuItem value="telecommunications">{t("Telecommunications")}</MenuItem>
                  <MenuItem value="tourism">{t("Tourism")}</MenuItem>
                  <MenuItem value="uncategorized">{t("Uncategorized")}</MenuItem>
                </Select>
              </FormControl>

              <DialogActions
                sx={{ justifyContent: 'center', gap: 2, flexDirection: 'column' }}
              >
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', gap: 2, width: '100%', mt: 3 }}
                >
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    sx={{
                      color: '#1c4ed8',
                      borderColor: '#1c4ed8',
                      borderRadius: '50px',
                      px: theme.spacing(5),
                      py: theme.spacing(1)
                    }}
                  >
                    {t('Cancel')}
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={
                      isLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <></>
                      )
                    }
                    sx={{
                      backgroundColor: '#1c4ed8',
                      borderRadius: '50px',
                      px: theme.spacing(3),
                      py: theme.spacing(1),
                      boxShadow: 'none',
                      '&:hover': { boxShadow: 'none' }
                    }}
                  >
                    {t('Add Business')}
                  </Button>
                </Box>
              </DialogActions>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <UpgradeDialog />
    </>
  );
};


// PropTypes for AddBusinessModal
AddBusinessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddBusiness: PropTypes.func.isRequired
};