import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Home } from './pages/Home/Home';
// Import only essential components directly, lazy load the rest
import { Footer } from './components/Footer/Footer';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './utils/AuthContext';
import { BusinessProvider } from './utils/BusinessContext';
import { startKeepAlive } from './utils/keepAlive';
import SignInSide from './pages/SignIn/SignInSide';
import { useTranslation } from 'react-i18next';
import './App.css';

// Lazy load non-critical components
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard').then(module => ({ default: module.Dashboard })));
const InvoiceDetailView = lazy(() => import('./pages/InvoiceDetailView/InvoiceDetailView').then(module => ({ default: module.InvoiceDetailView })));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs').then(module => ({ default: module.ContactUs })));
const Demo = lazy(() => import('./pages/DemoFrame/DemoFrame').then(module => ({ default: module.Demo })));
const SignUpSide = lazy(() => import('./pages/SignUp/SignUpSide'));
const VerifySide = lazy(() => import('./pages/SignUp/VerifySide'));
const AnalyticsView = lazy(() => import('./pages/Analytics/AnalyticsView').then(module => ({ default: module.AnalyticsView })));
const PricingView = lazy(() => import('./pages/Pricing/PricingView').then(module => ({ default: module.PricingView })));
const MembershipView = lazy(() => import('./pages/Membership/MembershipView').then(module => ({ default: module.MembershipView })));
const SettingsView = lazy(() => import('./pages/Settings/SettingsView').then(module => ({ default: module.SettingsView })));
const ReceiptConverter = lazy(() => import('./pages/ReceiptConverter/ReceiptConverter').then(module => ({ default: module.ReceiptConverter })));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));
const Records = lazy(() => import('./pages/Records/Records').then(module => ({ default: module.Records })));
const InvoiceItemsRecords = lazy(() => import('./pages/ItemRecords/ItemRecords').then(module => ({ default: module.InvoiceItemsRecords })));
const CreateInvoice = lazy(() => import('./pages/CreateInvoice/CreateInvoice').then(module => ({ default: module.CreateInvoice })));
const Payments = lazy(() => import('./pages/Payments/Payments').then(module => ({ default: module.Payments })));

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      // Using the translation function from props
      const { t } = this.props;
      
      return (
        <div style={{ 
          padding: '40px 20px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
          backgroundColor: '#f8fafc'
        }}>
          <h2 style={{ 
            color: '#1e293b',
            marginBottom: '16px',
            fontFamily: '"Funnel Display"'
          }}>{t("Oops! Something went wrong.")}</h2>
          <p style={{ 
            color: '#475569',
            marginBottom: '24px',
            maxWidth: '600px',
            fontFamily: '"Funnel Display"'
          }}>{t("We're sorry for the inconvenience. Please try refreshing the page.")}</p>
          <button onClick={() => window.location.reload()} style={{
            backgroundColor: '#1c4ed8', 
            color: 'white', 
            border: 'none', 
            padding: '12px 24px', 
            borderRadius: '25px', 
            cursor: 'pointer',
            fontFamily: '"Funnel Display"',
            fontSize: '16px',
            transition: 'background-color 0.2s ease',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#163bb7'
            }
          }}>
            {t('Refresh page')}
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Loading component for Suspense fallback
const LoadingComponent = () => {
  const { t } = useTranslation();
  
  return (
    <div className="app-loading" style={{ 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh',
      fontFamily: "Funnel Display", // Explicitly set font for this component only
    }}>
      <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
        <h2 style={{ fontFamily: "Funnel Display" }}>{t('Loading...')}</h2>
        <div className="loading-spinner" style={{ margin: 'auto' }}></div>
      </div>
    </div>
  );
};

// Custom hook to track page views on route changes
function PageTracker() {
  const location = useLocation();
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  useEffect(() => {
    if (gaId) {
      window.gtag('config', gaId, {
        page_path: location.pathname,
      });
    }
  }, [location, gaId]);

  return null;
}

function AppContent() {

  useEffect(() => {
    startKeepAlive();
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <BusinessProvider>
          <PageTracker />
          <Suspense fallback={<LoadingComponent />}>
          <ErrorBoundary>
              <Routes>
                {/* Login Required Pages */}
                <Route element={<PrivateRoute />}>
                  <Route path="/membership" element={<MembershipView />} />
                  <Route path="/settings" element={<SettingsView />} />
                </Route>

                {/* Public Pages */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/analytics" element={<AnalyticsView />} />
                <Route path="/invoice/:invoiceId" element={<InvoiceDetailView />} />
                <Route path="/receipt-converter" element={<ReceiptConverter />} />
                <Route path="/" element={<Home />} />
                <Route path="/pricing" element={<PricingView />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="/login" element={<SignInSide />} />
                <Route path="/register" element={<SignUpSide />} />
                <Route path="/verify" element={<VerifySide />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/records" element={<Records />} />
                <Route path="/item-records" element={<InvoiceItemsRecords />} />
                <Route path="/create-invoice" element={<CreateInvoice />} />
                <Route path="/payments" element={<Payments />} />
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </BusinessProvider>
      </AuthProvider>
      <Footer />
    </div>
  );
}

function App() {
  const { t } = useTranslation();
  
  useEffect(() => {
    const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (gaId) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      // Attach the gtag function to the window object, making it accessible globally
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };

      window.gtag("js", new Date());
      window.gtag("config", gaId);
    }
  }, []);

  return (
    <Router>
      <ErrorBoundary t={t}>
        <AppContent />
      </ErrorBoundary>
    </Router>
  );
}

export default App;
