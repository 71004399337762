import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Navbar } from '../../components/Navbar/Navbar';
import { Pricing } from '../../pages/Pricing/Pricing';
import { SignupForm } from '../../components/SignupForm/SignupForm';
import { HeroSection } from '../../components/HeroSection/HeroSection';
import { Box, Container} from '@mui/material';
import { FeatureCard } from '../../components/FeatureCard/FeatureCard';
import { StatSection } from '../../components/StatSection/StatSection';

const fadeIn = {
  initial: { opacity: 0, y: 40 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.8 },
};

export const Home = () => {

  // Animation controls and in-view detection for sections
  const [statsRef, statsInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const [featuresRef, featuresInView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const [pricingRef, pricingInView] = useInView({ triggerOnce: true, threshold: 0.4 });
  const [ctaRef, ctaInView] = useInView({ triggerOnce: true, threshold: 0.4 });

  const statsAnimation = useAnimation();
  const featuresAnimation = useAnimation();
  const pricingAnimation = useAnimation();
  const ctaAnimation = useAnimation();

  React.useEffect(() => {
    if (statsInView) statsAnimation.start('animate');
    if (featuresInView) featuresAnimation.start('animate');
    if (pricingInView) pricingAnimation.start('animate');
    if (ctaInView) ctaAnimation.start('animate');
  }, [statsInView, featuresInView, pricingInView, ctaInView, statsAnimation, featuresAnimation, pricingAnimation, ctaAnimation]);

  return (
    <Box
      className="min-h-screen"
      sx={{
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        '&.dark': {
          background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        },
        mb: -15,
      }}
    >
      <Container maxWidth="lg">
        <Navbar sideBarOpen={true} />

        {/* Hero Section */}
        <HeroSection />

        {/* Stats Section */}
        <motion.div ref={statsRef} initial="initial" animate={statsAnimation} variants={fadeIn}>
          <StatSection />
        </motion.div>

        {/* Features Section */}
        <motion.div ref={featuresRef} initial="initial" animate={featuresAnimation} variants={fadeIn}>
          <FeatureCard />
        </motion.div>

        {/* Pricing Section */}
        <motion.div ref={pricingRef} initial="initial" animate={pricingAnimation} variants={fadeIn}>
          <Box sx={{ width: '100%' }}>
            <Pricing />
          </Box>
        </motion.div>

        {/* CTA Section */}
        <motion.div ref={ctaRef} initial="initial" animate={ctaAnimation} variants={fadeIn}>
          <SignupForm />
        </motion.div>
        
      </Container>
    </Box>
  );
};
