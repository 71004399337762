import React, { useState } from 'react';
import { Button, IconButton, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import "./style.css";


export const SaveFrame = ({ onClose, onSave, onDiscard, fields, modifiedFields, url, invoiceId }) => {
  const [loading, setLoading] = useState(false);
  const axios = useAxios();

  function updateInitialFields(initialFields, updatedFields) {
    // Create a deep copy of initial fields to modify
    console.log(initialFields);
    console.log(updatedFields);
    let result = { ...initialFields };
    
    // Create a set of fields that should be kept (not nullified)
    const fieldsToKeep = new Set();
    fieldsToKeep.add('invoice_name');
    // Process invoice fields
    if (updatedFields.invoice) {
      updatedFields.invoice.forEach(item => {
        result[item.key] = item.value;
        fieldsToKeep.add(item.key);
      });
    }
    
    // Handle address updates
    const addressTypes = ['customerAddress', 'vendorAddress', 'billingAddress', 'shippingAddress', 'serviceAddress', 'remittanceAddress'];
    addressTypes.forEach(addressType => {
      if (updatedFields[addressType]) {
        // Convert camelCase to snake_case for the field name
        const fieldName = addressType.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        
        // Create an object to store address fields
        const addressObj = {
          house_number: '',
          po_box: '',
          road: '',
          city: '',
          state: '',
          postal_code: '',
          country_region: '',
          street_address: '',
          unit: '',
          city_district: '',
          state_district: '',
          suburb: '',
          house: '',
          level: ''
        };
        
        // Update values from the updated fields
        updatedFields[addressType].forEach(item => {
          addressObj[item.key] = item.value;
        });
        
        // Convert to string format
        result[fieldName] = JSON.stringify(addressObj).replace(/"/g, "'");
        fieldsToKeep.add(fieldName);
      }
    });

    // Process custom fields
    if (updatedFields.customFields) {
      fieldsToKeep.add('custom_fields');
    
      // Step 1: Create a set of keys that are present in updatedFields.customFields
      const newKeys = new Set(updatedFields.customFields.map(item => item.key));
    
      // Step 2: Update result.custom_fields with new key-value pairs from updatedFields
      updatedFields.customFields.forEach((item) => {
        if (item) {
          result.custom_fields[item.key] = item.value; // Safely add each key-value pair
        }
      });
    
      // Step 3: Set keys to null in result.custom_fields if they're not in updatedFields
      Object.keys(result.custom_fields).forEach((key) => {
        if (!newKeys.has(key)) {
          delete result.custom_fields[key]; // Set value to null if key is not in updated fields
        }
      });
    } else if (result.custom_fields && Object.keys(result.custom_fields).length === 0) {
      result.custom_fields = null;
    }  
    
    // Process invoice items
    if (updatedFields.invoiceItems) {
      fieldsToKeep.add('invoice_items');
      
      if (result.invoice_items) {
        result.invoice_items.forEach((item, idx) => {
          if (item) {
            Object.keys(item).forEach(key => {
              result.invoice_items[idx][key] = null;
            });
          }
        });
      }
      
      updatedFields.invoiceItems.forEach((itemArray, index) => {
        if (!result.invoice_items) {
          result.invoice_items = [];
        }
        
        if (!result.invoice_items[index]) {
          result.invoice_items[index] = {};
        }
        
        itemArray.forEach(update => {
          const { key, value } = update;
          result.invoice_items[index][key] = value;
        });
      });
    }
    
    // Define protected fields
    const protectedFields = [
      'amount_due',
      'customer_id',
      'customer_name',
      'due_date',
      'invoice_date',
      'invoice_id_number',
      'invoice_name',
      'previous_unpaid_balance',
      'purchase_order',
      'service_start_date',
      'service_end_date',
      'subtotal',
      'total_tax',
      'vendor_name',
      'custom_fields'
    ];
    
    // Nullify fields based on conditions
    Object.keys(result).forEach(key => {
      if (!fieldsToKeep.has(key) && key !== 'invoice_items') {
        if (!updatedFields.invoice) {
          if (!protectedFields.includes(key) && 
              !key.endsWith('address') && 
              !key.endsWith('recipient')) {
            result[key] = null;
          }
        } else {
          if (!key.endsWith('address') && !key.endsWith('recipient')) {
            result[key] = null;
          }
        }
      }
    });
    
    return result;
  }



  const handleSave = async () => {
    setLoading(true); // Show loading while saving

    const changes = updateInitialFields(fields, modifiedFields);
    console.log(changes);

    try {
      // Prepare and send the PUT request
      const response = await axios.put(`${url}/api/invoices/${invoiceId}/items/`, 
        changes,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log('Update successful:', response.data);
    } catch (error) {
        console.error('Error updating fields:', error);
        throw error;
    } finally {
        setLoading(false); // Ensure loading is hidden after the operation
        onSave();
    }
  };


  const handleDiscard = () => {
    onDiscard();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {/* Backdrop to darken the background */}
      <div className="save-backdrop" ></div>

      {/* Loading Screen */}
      {loading && (
        <div className="loading-overlay">
            <CircularProgress size={22} style={{ color: "#1c4ed8" }} />
            <Typography sx={{fontWeight: "bold", color: "#1c4ed8", fontSize: '1.3rem', marginLeft: "12px" }}>Saving...</Typography>
        </div>
      )}

      {/* Save Frame */}
      <div className="save-frame">
        <div className="content-wrapper">
          {/* Close Button */}
          <IconButton
            size="medium"
            sx={{
              position: "absolute",
              top: 18,
              right: 18,
              border: 1,
              borderColor: "#1c4ed8",
              color: "#1c4ed8",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f0f4ff" },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Confirmation Section */}
          <div className="confirmation-content">
            <h2 className="confirmation-title">Save Changes</h2>
            <p className="confirmation-subtitle">
              You have made changes. Would you like to save them before closing?
            </p>

            {/* Save/Discard Buttons */}
            <div className="button-container">
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#1c4ed8",
                  padding: "10px 20px",
                  borderRadius: '50px',
                  "&:hover": { backgroundColor: "#163bb7" },
                  marginRight: "10px",
                }}
                onClick={handleSave}
                variant="contained"
                disabled={loading} // Disable button if loading
              >
                Save
              </Button>
              <Button
                sx={{
                  color: "#ff1744",
                  borderColor: "#ff1744",
                  padding: "10px 20px",
                  borderRadius: '50px',
                  "&:hover": { backgroundColor: "#ffe7e9" },
                }}
                onClick={handleDiscard}
                variant="outlined"
              >
                Discard
              </Button>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .save-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1000;
        }
        .save-frame {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 400px;
          padding: 20px;
          background-color: #fff;
          z-index: 1100;
          border-radius: 8px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
        .loading-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 400px;
          height: 210px;
          padding: 20px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1200;
          border-radius: 8px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
      `}</style>
    </>
  );
};
