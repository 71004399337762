import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Pricing = () => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handlePricing = () => navigate('/pricing');
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "50vh",
        overflow: "hidden", // Ensures content stays inside the view
      }}
    >
      {/* Circle Object */}
      <Box
        sx={{
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "350px",
          height: "350px",
          borderRadius: "50%",
          background: "radial-gradient(ellipse 90% 80% at 50% 10%, hsl(210, 100%, 90%), transparent)",
          zIndex: -1, // Sends the circle to the background
        }}
      ></Box>

      {/* Text Content */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 500,
          mb: 2,
        }}
      >
        {t("Automate, Analyze, and Unlock Your Invoice Insights")}
      </Typography>

      {/* Button */}
      <Button
        variant="contained"
        className="button"
        color="primary"
        size="small"
        onClick={handlePricing}
        sx={{ px: 2, py: 1, fontSize: '1rem', borderRadius: '50px', 
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", backgroundColor: "#1c4ed8" }}
      >
        {t("See our pricing plans")}
      </Button>
    </Box>
  );
};
