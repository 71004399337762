//AuthContext.js
import { createContext, useState, useEffect, useMemo } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loginUser = async (username, password, setLoginError) => {
    try {
        const response = await fetch(`${url}/api/token/`, { 
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username,
            password
          })
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          setAuthTokens(data);
          const decodedToken = jwtDecode(data.access);
          setUser(decodedToken);
          localStorage.setItem("authTokens", JSON.stringify(data))
          setLoginError('');
          return [true, data];
        }
        if (response.status === 401) {
          setLoginError('Login unsuccessful. Please verify your credentials and try again.');
          return false;
        } else {
          setLoginError('An unexpected error occurred. Please try again.');
          return false;
        }
  
    } catch (error) {
      // Handle error messages from server response
        setLoginError('An unexpected error occurred. Please try again.');
        return false;
      } 
      
  };

  const registerUser = async (username, password, first_name, last_name, email, company, setRegisterError) => {
    try {
      const response = await fetch(`${url}/api/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          password,
          first_name,
          last_name,
          email,
          company
        })
      });
      if (response.status === 201) {
        navigate("/verify");
      } else {
        const errorData = await response.json();
        if (errorData.username) {
          setRegisterError(errorData.username[0]); // Get the first error message for username
        } else {
          setRegisterError('Sign Up failed. Please try again.');
        }
      }
    } catch (error) {
      setRegisterError(error|| 'Sign Up failed. Please try again.');
    }
  };

  const verifyUser = async (code,  setCodeErrorMessage) => {
    try {
      const response = await fetch(`${url}/api/verify/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "code" : code
        })
      });
      if (response.status === 200) {
        localStorage.setItem("registerMessage", "Registration successful! Please log in.");
        navigate("/login");
      } else {
        setCodeErrorMessage('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      setCodeErrorMessage('Please enter a valid verification code.')
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("username");
    localStorage.removeItem('email')
    localStorage.removeItem("company");
    navigate("/");
  };


  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    verifyUser,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};