import React, { useState, useCallback, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  Divider,
  AppBar,
  FormControl
} from "@mui/material";
import AuthContext from '../../utils/AuthContext';
import { PersonOutlined, Logout, EmojiEventsOutlined, VisibilityOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './LanguageSwitcher';

const StyledToolbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  padding: '8px 15px',
  borderRadius: `calc(${theme.shape.borderRadius}px + 28px)`,
}));

export const Navbar = ({ sideBarOpen }): JSX.Element => {
  const demo = !localStorage.getItem("authTokens");
  const username = localStorage.getItem('username') || 'DemoUser';
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMembership = useCallback(() => navigate('/membership'), [navigate]);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const handleGoDashboardDemo = () => {
    navigate('/dashboard')
  };

  const handleGoDashboard = () => navigate('/dashboard');
  const handleGoDemo = () => navigate('/demo');
  const handleLogin = () => navigate('/login');
  const handleGoHome = () => navigate('/');
  const handleGoContact = () => navigate('/contact');
  const handlePricing = () => navigate('/pricing');
  const { logoutUser } = useContext(AuthContext);
  const handleLogout = () => {
    logoutUser();
  }
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const isHomePage = ["/", "/login", "/register"].includes(location.pathname)


  return (
    <AppBar sx={{ boxShadow: 0, mt: '10px', bgcolor: isHomePage ? 'transparent' : 'transparent', backgroundImage: 'none', zIndex: 2 }}>
      <Container sx={{width: sideBarOpen ? '100%' : "70%" }} >
        <StyledToolbar >
          <Box display="flex" alignItems="center" onClick={handleGoHome} sx={{ cursor: 'pointer' }}>
            <img
              src="https://c.animaapp.com/kAl1n2qv/img/yourdraftlogo-v1-2@2x.png"
              alt="Company Logo"
              style={{ width: "52.1px", height: "51px" }}
            />
            <Typography variant="h4" sx={{ ml: 2, color: "#004080" }}>
              YourDraft
            </Typography>
          </Box>

          {/* Desktop Menu */}
          <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center', textWrap: 'nowrap' }}>
            {isHomePage && demo && (
              <Box display="flex" justifyContent='center'
              >
                <Button
                  variant="outlined" startIcon={<VisibilityOutlined />}
                  onClick={handleGoDashboardDemo}
                  sx={demoButtonStyles}
                >
                  <span >{t('Try Free Demo')}</span>
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleGoDemo}
                  sx={buttonStyles}
                >
                  {t('How It Works')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handlePricing}
                  sx={buttonStyles}
                >
                  {t('Pricing')}
                </Button>
              </Box>
            )}
          </Box>

          <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center', textWrap: 'nowrap' }}>
          {demo ? (
              <Button
              variant="outlined"
              startIcon={<PersonOutlined sx={{ color: "#1c4ed8" }} />}
              onClick={handleLogin}
              sx={buttonStyles}
            >
              {t('Sign In')}
            </Button>
          ) : (
            <FormControl
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",
                  "&:hover": {
                    backgroundColor: alpha("#1c4ed8", 0.05),
                  },
                },
                "& .MuiSelect-icon": {
                  color: "#1c4ed8",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={handleMenuOpen}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                  padding: "8px 12px",
                  borderRadius: "30px",
                  backgroundColor: alpha("#1c4ed8", 0.05),
                }}
              >
                <PersonOutlined sx={{ color: "#1c4ed8" }} />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  {t("Welcome back")}, {username}
                </Typography>
              </div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                  style: {
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    overflow: "hidden",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleMembership();
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px", // Adds space between the icon and text
                    "&:hover": {
                      backgroundColor: alpha("#1c4ed8", 0.1),
                    },
                  }}
                >
                  <EmojiEventsOutlined sx={{ color: "#1c4ed8" }} />
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("My Membership")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleLogout();
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px", // Adds space between the icon and text
                    "&:hover": {
                      backgroundColor: alpha("#1c4ed8", 0.1),
                    },
                  }}
                >
                  <Logout sx={{ color: "#1c4ed8" }} />
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("Logout")}
                  </Typography>
                </MenuItem>
              </Menu>
            </FormControl>
          )}
            <LanguageSwitcher />
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton onClick={toggleDrawer(true)}><MenuIcon /></IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={toggleDrawer(false)}><CloseRoundedIcon /></IconButton>
                </Box>
                <MenuItem onClick={handleGoDashboard} sx={buttonStyles}>My Dashboard</MenuItem>
                <MenuItem onClick={handleGoContact} sx={buttonStyles}>Contact Us</MenuItem>
                {isHomePage && (
                  <MenuItem onClick={handleGoDemo}sx={demoButtonStyles}>Book A Demo!</MenuItem>
                )}
                <Divider sx={{ my: 3 }} />
                <LanguageSwitcher/>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
};

// Button styles
const buttonStyles = {
  borderColor: 'transparent', 
  color: 'black',
  borderRadius: '50px',
};

const demoButtonStyles = {
  color: '#1c4ed8',
  borderColor: '#1c4ed8',
  borderRadius: '50px',
};


