import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InvoiceStatusChart from './InvoiceStatusChart';
import InvoiceTimelineChart from './InvoiceTimelineChart';
import InvoiceMetricsCards from './InvoiceMetricsCards';
import InvoiceDetailTable from './InvoiceDetailTable';
import InvoiceVendorAnalysis from './InvoiceVendorAnalysis';
import InvoiceCustomerAnalysis from './InvoiceCustomerAnalysis';

// Convert string dates to Date objects
const invoiceData = [
  {
      "id": "INV-001",
      "customer": "Wayne Enterprises",
      "vendor": "Lab Equipment Ltd.",
      "amount": 19227.22,
      "status": "Approved",
      "submittedAt": "2023-04-04",
      "dueDate": "2023-04-21",
      "processed": false
  },
  {
      "id": "INV-002",
      "customer": "Wayne Enterprises",
      "vendor": "Tech Suppliers",
      "amount": 17615.04,
      "status": "Pending Review",
      "submittedAt": "2023-04-14",
      "dueDate": "2023-05-13",
      "processed": false
  },
  {
      "id": "INV-003",
      "customer": "InGen",
      "vendor": "AI Solutions",
      "amount": 7795.38,
      "status": "In Review",
      "submittedAt": "2023-04-18",
      "dueDate": "2023-05-12",
      "processed": true
  },
  {
      "id": "INV-004",
      "customer": "Stark Industries",
      "vendor": "AI Solutions",
      "amount": 8703.16,
      "status": "In Review",
      "submittedAt": "2023-05-18",
      "dueDate": "2023-05-28",
      "processed": false
  },
  {
      "id": "INV-005",
      "customer": "Acme Inc.",
      "vendor": "Industrial Solutions",
      "amount": 14803.55,
      "status": "Approved",
      "submittedAt": "2023-04-03",
      "dueDate": "2023-04-17",
      "processed": true
  },
  {
      "id": "INV-006",
      "customer": "Daily Bugle",
      "vendor": "Research Materials Inc.",
      "amount": 12585.52,
      "status": "In Review",
      "submittedAt": "2023-04-15",
      "dueDate": "2023-05-13",
      "processed": false
  },
  {
      "id": "INV-007",
      "customer": "Umbrella Corp.",
      "vendor": "AI Solutions",
      "amount": 16397.97,
      "status": "In Review",
      "submittedAt": "2023-04-25",
      "dueDate": "2023-05-24",
      "processed": false
  },
  {
      "id": "INV-008",
      "customer": "Weyland Corp.",
      "vendor": "Print Supplies Co.",
      "amount": 13525.05,
      "status": "In Review",
      "submittedAt": "2023-04-12",
      "dueDate": "2023-05-01",
      "processed": false
  },
  {
      "id": "INV-009",
      "customer": "Globex Corp.",
      "vendor": "Electronics Ltd.",
      "amount": 10352.62,
      "status": "In Review",
      "submittedAt": "2023-05-28",
      "dueDate": "2023-06-15",
      "processed": false
  },
  {
      "id": "INV-010",
      "customer": "Weyland Corp.",
      "vendor": "Space Tech Inc.",
      "amount": 8544.14,
      "status": "Pending Review",
      "submittedAt": "2023-04-22",
      "dueDate": "2023-05-12",
      "processed": false
  },
  {
      "id": "INV-011",
      "customer": "Umbrella Corp.",
      "vendor": "Tech Suppliers",
      "amount": 18447.85,
      "status": "Approved",
      "submittedAt": "2023-05-19",
      "dueDate": "2023-06-03",
      "processed": false
  },
  {
      "id": "INV-012",
      "customer": "Wayne Enterprises",
      "vendor": "Print Supplies Co.",
      "amount": 11441.48,
      "status": "Approved",
      "submittedAt": "2023-04-04",
      "dueDate": "2023-04-17",
      "processed": true
  },
  {
      "id": "INV-013",
      "customer": "InGen",
      "vendor": "Biotech Supplies",
      "amount": 5766.28,
      "status": "Pending Review",
      "submittedAt": "2023-04-17",
      "dueDate": "2023-05-16",
      "processed": false
  },
  {
      "id": "INV-014",
      "customer": "Wayne Enterprises",
      "vendor": "Electronics Ltd.",
      "amount": 18961.83,
      "status": "Pending Review",
      "submittedAt": "2023-04-05",
      "dueDate": "2023-05-02",
      "processed": true
  },
  {
      "id": "INV-015",
      "customer": "Daily Bugle",
      "vendor": "Widgets Co.",
      "amount": 8139.84,
      "status": "Pending Review",
      "submittedAt": "2023-05-10",
      "dueDate": "2023-05-28",
      "processed": true
  },
  {
      "id": "INV-016",
      "customer": "Weyland Corp.",
      "vendor": "Industrial Solutions",
      "amount": 18675.75,
      "status": "Pending Review",
      "submittedAt": "2023-04-10",
      "dueDate": "2023-05-05",
      "processed": true
  },
  {
      "id": "INV-017",
      "customer": "Cyberdyne Systems",
      "vendor": "Electronics Ltd.",
      "amount": 15650.77,
      "status": "In Review",
      "submittedAt": "2023-05-22",
      "dueDate": "2023-06-04",
      "processed": true
  },
  {
      "id": "INV-018",
      "customer": "Wayne Enterprises",
      "vendor": "Electronics Ltd.",
      "amount": 2723.86,
      "status": "In Review",
      "submittedAt": "2023-04-13",
      "dueDate": "2023-05-13",
      "processed": false
  },
  {
      "id": "INV-019",
      "customer": "Stark Industries",
      "vendor": "Print Supplies Co.",
      "amount": 7103.87,
      "status": "Pending Review",
      "submittedAt": "2023-05-17",
      "dueDate": "2023-06-01",
      "processed": false
  },
  {
      "id": "INV-020",
      "customer": "Globex Corp.",
      "vendor": "Space Tech Inc.",
      "amount": 6681.92,
      "status": "Approved",
      "submittedAt": "2023-05-02",
      "dueDate": "2023-05-13",
      "processed": true
  },
  {
      "id": "INV-021",
      "customer": "Stark Industries",
      "vendor": "Print Supplies Co.",
      "amount": 7685.79,
      "status": "Approved",
      "submittedAt": "2023-05-17",
      "dueDate": "2023-06-02",
      "processed": false
  },
  {
      "id": "INV-022",
      "customer": "Wayne Enterprises",
      "vendor": "Widgets Co.",
      "amount": 6265.82,
      "status": "Approved",
      "submittedAt": "2023-05-05",
      "dueDate": "2023-05-15",
      "processed": true
  },
  {
      "id": "INV-023",
      "customer": "Weyland Corp.",
      "vendor": "Biotech Supplies",
      "amount": 12005.99,
      "status": "Pending Review",
      "submittedAt": "2023-05-28",
      "dueDate": "2023-06-19",
      "processed": true
  },
  {
      "id": "INV-024",
      "customer": "InGen",
      "vendor": "AI Solutions",
      "amount": 17981.9,
      "status": "Approved",
      "submittedAt": "2023-04-22",
      "dueDate": "2023-05-13",
      "processed": false
  },
  {
      "id": "INV-025",
      "customer": "InGen",
      "vendor": "Research Materials Inc.",
      "amount": 18193.21,
      "status": "Pending Review",
      "submittedAt": "2023-04-26",
      "dueDate": "2023-05-09",
      "processed": false
  },
  {
      "id": "INV-026",
      "customer": "Acme Inc.",
      "vendor": "Research Materials Inc.",
      "amount": 13206.04,
      "status": "Approved",
      "submittedAt": "2023-04-25",
      "dueDate": "2023-05-05",
      "processed": true
  },
  {
      "id": "INV-027",
      "customer": "Globex Corp.",
      "vendor": "Industrial Solutions",
      "amount": 15071.42,
      "status": "Approved",
      "submittedAt": "2023-05-15",
      "dueDate": "2023-06-02",
      "processed": false
  },
  {
      "id": "INV-028",
      "customer": "InGen",
      "vendor": "AI Solutions",
      "amount": 6188.77,
      "status": "Pending Review",
      "submittedAt": "2023-04-26",
      "dueDate": "2023-05-13",
      "processed": false
  },
  {
      "id": "INV-029",
      "customer": "InGen",
      "vendor": "Lab Equipment Ltd.",
      "amount": 14613.42,
      "status": "Approved",
      "submittedAt": "2023-05-07",
      "dueDate": "2023-05-20",
      "processed": false
  },
  {
      "id": "INV-030",
      "customer": "Oscorp",
      "vendor": "Tech Suppliers",
      "amount": 6756.86,
      "status": "Approved",
      "submittedAt": "2023-04-19",
      "dueDate": "2023-05-19",
      "processed": true
  }
];

export default function InvoiceAnalyticsDashboard() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1500px' } }}>
      {/* Overview Section */}
      <Typography component="h2" variant="h5" sx={{ mb: 2 }}>Overview</Typography>
      <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
        {/* Metrics Cards */}
        <Grid item xs={12} sm={6} lg={3}>
          <InvoiceMetricsCards data={invoiceData} />
        </Grid>
        {/* Vendor and Customer Analysis */}
        <Grid container spacing={2} xs={12} sm={6} lg={3}>
          <Grid container spacing={2}>
              <InvoiceVendorAnalysis data={invoiceData} />
              <InvoiceCustomerAnalysis data={invoiceData} />
          </Grid>
        </Grid>
      </Grid>

      {/* Chart Section */}
      <Grid container spacing={2} xs={12} sm={6} lg={3}>
        <Grid container spacing={2}>
          <InvoiceStatusChart data={invoiceData} />
          <InvoiceTimelineChart data={invoiceData} />
        </Grid>
      </Grid>

      {/* Details Section */}
      <Typography component="h3" variant="subtitle2 bold" sx={{ mt: 2}}>
        Details
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ mt: 2}}>
        <Grid item xs={12}>
          <InvoiceDetailTable data={invoiceData} />
        </Grid>
      </Grid>
    </Box>
  );
}