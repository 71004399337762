import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  IconButton,
  Container,
  Chip,
  Tooltip,
  TextField,
  InputAdornment,
  ClickAwayListener,
  Menu,
  MenuItem,
  Stack,
  CircularProgress,
  Typography
} from "@mui/material";
import { DataGrid} from '@mui/x-data-grid';
import { plPL } from '@mui/x-data-grid/locales/';  // Polish locale
import {
  Download as DownloadIcon,
  Upload as UploadIcon,
  Search as SearchIcon,
  MoreHoriz as MoreHorizIcon,
  Pending as PendingIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Article,
  Sync,
  Done,
} from "@mui/icons-material";
import { MapPinPlus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

import useAxios from "../../utils/useAxios";
import { motion } from "framer-motion";
import { UploadFrame } from "../../components/UploadFrame/UploadFrame";
import { DownloadFrame } from "../../components/DownloadFrame/DownloadFrame";
import { Navbar } from "../../components/Navbar/Navbar";
import { AddFrame } from "../../components/AddFrame/AddFrame";
import { useNavigate } from 'react-router-dom';
import { DocumentContent } from "../../components/DocumentContent/DocumentContent";
import SideBar from "../../components/SideBar/SideBar";

// Helper functions for status-related operations
const getStatusIcon = (status) => {
  switch (status) {
    case "PENDING_REVIEW":
      return <PendingIcon />;
    case "IN_REVIEW":
      return <EditIcon />;
    case "APPROVED":
      return <CheckCircleIcon />;
    default:
      return <PendingIcon />;
  }
};

const fadeIn = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

export const Dashboard = () => {
  const demo = !localStorage.getItem("authTokens");
  const { t, i18n } = useTranslation();
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const axios = useAxios();
  
  // State management
  const company = localStorage.getItem('company') || 'Demo Company';
  const [invoices, setInvoices] = useState([]);
  const [, setNumPages] = useState(null);
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [showDownloadFrame, setShowDownloadFrame] = useState(false);
  const [showAddFrame, setShowAddFrame] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [, setAllChecked] = useState(false);
  const [activeTab, setActiveTab] = useState("All Invoices");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedInvoiceIds, setCheckedInvoiceIds] = useState([]);

  const searchRef = useRef(null);
  const open = Boolean(anchorEl);

  // Memoized TabButton component
  const TabButton = React.memo(({ tab, activeTab, onClick }) => (
    <Button
      variant={activeTab === tab ? "contained" : "text"}
      onClick={() => onClick(tab)}
      startIcon={
        tab === "All Invoices" ? <Article /> : 
        tab === "Approved" ? <Done /> : 
        <Sync />
      }
      sx={{
        size: "small",
        borderRadius: '50px',
        backgroundColor: activeTab === tab ? 'white' : 'transparent',
        color: '#1c4ed8',
        '&:hover': {
          color: '#1c4ed8',
          backgroundColor: 'white',
        },
      }}
    >
      {t(tab)}
    </Button>
  ));

  const renderStatusChip = (status) => {
    const statusConfig = {
      'PENDING_REVIEW': { label: t('Pending Review'), color: 'warning' },
      'IN_REVIEW': { label: t('In Review'), color: 'info' },
      'APPROVED': { label: t('Approved'), color: 'success' }
    };
  
    const config = statusConfig[status] || { label: 'Unknown', color: 'default' };
    return (
      <Chip
        label={config.label.toUpperCase()}
        color={config.color}
        icon={getStatusIcon(status)}
        variant="outlined"
        size="small"
      />
    );
  }

  // Memoized URL
  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

    // Columns Definition
    const columns = useMemo(() => [
      { 
        field: 'thumbnail', 
        headerName: t('File'), 
        flex: 0.25, 
        minWidth: 25,
        renderCell: (params) => {
          const filePath = `${url}${params.row.file}`;
          return (
            <Box mt={1} sx={{ alignItems: 'center', justifyContent: 'center'}}>
              <DocumentContent 
                path={filePath}
                pageNumber={1}
                scale={0.1}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
              />
            </Box>
          );
        },
      },
      { 
        field: 'fileName', 
        headerName: t('File Name'), 
        flex: 0.7, 
        description: t('The name of the file.'),
      },
      { 
        field: 'status', 
        headerName: t('Status'), 
        flex: 0.5, 
        renderCell: (params) => renderStatusChip(params.value),
        description: t('The current processing status of the file.')
      },
      { 
        field: 'submittedAt', 
        headerName: t('Update Date'), 
        flex: 0.5,
        description: t('The upload date of the file.'),
      },
      { 
        field: 'modifiedAt', 
        headerName: t('Last Modified'), 
        flex: 0.5,
        description: t('The last update date of the file.'),
      },
      { 
        field: 'uploaded_by', 
        headerName: t('Updated By'), 
        flex: 0.5,
  
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [t, url]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Memoized filtered invoices
  const filteredInvoices = useMemo(() => {
    if (!searchQuery) return invoices;
    return invoices.filter(invoice => 
      invoice.file.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [invoices, searchQuery]);

  // Debounce utility function
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // Memoized handlers
  const handleUploadClick = useCallback(() => setShowUploadFrame(true), []);
  const handleDownloadClick = useCallback(() => setShowDownloadFrame(true), []);
  const handleAddClick = useCallback(() => setShowAddFrame(true), []);

  const fetchInvoices = useCallback(async () => {
    if (demo) {
      try {
        const response = await fetch(`${url}/api/invoices/`);
        const data = await response.json();
        if (data.message === "Success") {
          const processedInvoices = data.data.map(invoice => ({
            ...invoice,
            fileName: invoice.file.split('/').pop().split('.')[0],
            submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
            modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
            
          }));
          setInvoices(processedInvoices);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    } else {
      try {
        const response = await axios.get(`${url}/api/invoices/`);
        if (response.data.message === "Success") {
          const processedInvoices = response.data.data.map(invoice => ({
            ...invoice,
            fileName: invoice.file.split('/').pop().split('.')[0],
            submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
            modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
          }));
          setInvoices(processedInvoices);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);
  

  const handleCloseDownloadFrame = useCallback(() => {
    setShowDownloadFrame(false);
  }, []);

  const handleRowClick = (params) => {
    console.log(params)
    const invoice = {
      id: params.row.id,
      file: params.row.file,
      status: params.row.status,
      addedBy: params.row.uploaded_by,
      addedOn: params.row.uploaded_at
    }
    handleGoInvoice([invoice]);
  };

  const updateStatus = useCallback(async (invoiceId) => {
    if (demo) {
      try {
        const response = await fetch(`${url}/api/invoices/${invoiceId}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ status: "IN_REVIEW" })
        });
        
        if (!response.ok) {
          throw new Error(response.statusText);
        }
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    } else {
      try {
        await axios.post(`${url}/api/invoices/${invoiceId}/`, 
          { status: "IN_REVIEW" },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);

  const handleGoInvoice = useCallback(async(invoices) => {
    const invoiceIds = invoices.map((invoice) => invoice.id).join(',');
    const filePaths = invoices.map((invoice) => `${url}${invoice.file}`).join(',');
    const statuses = invoices.map((invoice) => invoice.status);
    const invoiceId = invoiceIds.split(",")[0]
    const status = statuses[0]
    const addedBy = invoices.map((invoice) => invoice.addedBy)[0];
    const addedOn = invoices.map((invoice) => invoice.addedOn)[0];

    // If any invoice has "PENDING_REVIEW" status, update it
    if (status === "PENDING_REVIEW") {
        updateStatus(invoiceId)
        .then(() => {
        navigate(`/invoice/${invoiceId}?invoiceIds=${encodeURIComponent(invoiceIds)}&filepaths=${encodeURIComponent(filePaths)}&addedBy=${addedBy}&addedOn=${addedOn}`);
      }).catch((error) => {
        console.error('Navigation error:', error);
      });
    } else {
      // Navigate directly if all statuses are "IN_REVIEW"
      navigate(`/invoice/${invoiceId}?invoiceIds=${encodeURIComponent(invoiceIds)}&filepaths=${encodeURIComponent(filePaths)}&addedBy=${addedBy}&addedOn=${addedOn}`);
    }
  }, [navigate, updateStatus, url]);


  const handleCloseUploadFrame = useCallback(async (invoices) => {
    fetchInvoices();
    if (invoices.length === 0) {
      setShowUploadFrame(false);
      return;
    }
    else {
      if (invoices.length === 1) {
        // If only one invoice, navigate directly to it
        const invoice = invoices[0];
        await handleGoInvoice([invoice]); // Await to ensure completion before moving forward
      } else {
        // If multiple invoices, pass all to the function
        await handleGoInvoice(invoices); // Await for multiple invoices as well
      }
    }
    // Only hide the upload frame after the invoice navigation is completed
    setShowUploadFrame(false);

}, [fetchInvoices, handleGoInvoice]);

  const handleCloseAddFrame = useCallback(async (invoices) => {
    fetchInvoices();
    if (invoices.length === 0) {
      setShowAddFrame(false);
      return;
    }
    else {
      if (invoices.length === 1) {
        // If only one invoice, navigate directly to it
        const invoice = invoices[0];
        await handleGoInvoice([invoice]); // Await to ensure completion before moving forward
      } else {
        // If multiple invoices, pass all to the function
        await handleGoInvoice(invoices); // Await for multiple invoices as well
      }
    }
    // Only hide the upload frame after the invoice navigation is completed
    setShowAddFrame(false);

  }, [fetchInvoices, handleGoInvoice]);

  const handleSetActiveTab = useCallback((newTab) => {
    setActiveTab(newTab);
    
    let status = null;
    if (newTab === 'In Review') {
      status = 'IN_REVIEW';
    } else if (newTab === 'Approved') {
      status = 'APPROVED';
    }
    
    const apiUrl = `${url}/api/invoices/${status ? `?status=${status}` : ''}`;
    if (demo) {
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          if (data.message === "Success") {
            const processedInvoices = data.data.map(invoice => ({
              ...invoice,
              fileName: invoice.file.split('/').pop().split('.')[0],
              submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
              modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
            }));
            setInvoices(processedInvoices);
          }
        })
        .catch(error => {
          console.error("Error fetching invoices:", error);
        });
    } else {
      axios.get(apiUrl)
      .then(response => {
        if (response.data.message === "Success") {
          const processedInvoices = response.data.data.map(invoice => ({
            ...invoice,
            fileName: invoice.file.split('/').pop().split('.')[0],
            submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
            modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
          }));
          setInvoices(processedInvoices);
        }
      })
      .catch(error => {
        console.error("Error fetching invoices:", error);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);
      await Promise.all(
        checkedInvoiceIds.map((invoiceId) =>
          demo
            ? fetch(`${url}/api/invoices/${invoiceId}/`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            : axios.delete(`${url}/api/invoices/${invoiceId}/`, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
        )
      );      
      await fetchInvoices();
      setCheckedInvoiceIds([]);
      setDeleteLoading(false);
      setAnchorEl(null);
    } catch (error) {
      console.error('Delete error:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedInvoiceIds, fetchInvoices, demo, url]);

  const handleSearchChange = useMemo(() => {
    return debounce((value) => {
      const apiUrl = `${url}/api/invoices/${value ? `?search=${value}` : ''}`;
      if (demo) {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (data.message === "Success") {
              const processedInvoices = data.data.map(invoice => ({
                ...invoice,
                fileName: invoice.file.split('/').pop().split('.')[0],
                submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
                modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
              }));
              setInvoices(processedInvoices);
            }
          })
          .catch(error => {
            console.error("Error fetching invoices:", error);
          });
      } else {
        axios.get(apiUrl)
        .then(response => {
          if (response.data.message === "Success") {
            const processedInvoices = response.data.data.map(invoice => ({
              ...invoice,
              fileName: invoice.file.split('/').pop().split('.')[0],
              submittedAt: new Date(invoice.updated_at).toLocaleDateString()
            }));
            setInvoices(processedInvoices);
          }
        })
        .catch(error => {
          console.error("Error fetching invoices:", error);
        });
      }
    }, 300);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);

  // Effects
  useEffect(() => {
    if (filteredInvoices.length === 0) {
      setAllChecked(false);
    } else {
      setAllChecked(checkedInvoiceIds.length === filteredInvoices.length);
    }
  }, [checkedInvoiceIds, filteredInvoices]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    if (showSearch && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showSearch]);

  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  return (
    <Box width="100%" bgcolor="white" 
    sx={{
      backgroundImage:
        'radial-gradient(ellipse 80% 80% at 50% -20%, hsl(210, 100%, 90%), transparent)',
      '&.dark': {
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
      },
    }}>
      <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={true} />
      <Navbar sideBarOpen={openSideBar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            ml: 50,
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 },
          }}
        >
          <Box sx={{ width: openSideBar ? '100%' : '70%', maxWidth: { sm: '100%', md: '1700px'} }}>
            <Container sx={{ mt: 14}}>
              <Box display='flex' justifyContent="right" alignItems="flex-end" flexDirection="column" >
                <Typography variant="h5" >
                  {company}
                  <MapPinPlus color="#1c4ed8" size="20px" style={{marginLeft: 2, cursor: 'pointer'}}/>
                </Typography>
              </Box>
            </Container>
            <Container>
              <Box mt={3} >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}
                  width={ "100%"}>
                  <Box display="flex" justifyContent="left" alignItems="center">
                      <Button
                        variant="contained"
                        size="small"
                        className="button"
                        startIcon={<UploadIcon />}
                        onClick={handleUploadClick}
                        sx={{ mr: 2, borderRadius: '50px' }}
                      >
                        {t("Upload")}
                      </Button>
                      <Box sx={{backgroundColor: '#f7f7f7', borderRadius: '50px'}}>
                        {["All Invoices", "In Review", "Approved"].map((tab) => (
                          <TabButton
                            key={tab}
                            tab={tab}
                            activeTab={activeTab}
                            onClick={() => handleSetActiveTab(tab)}
                          />
                          ))}
                      </Box>
                      <ClickAwayListener onClickAway={() => {
                      if (searchQuery.trim() === "") {
                        setShowSearch(false);
                      }
                      }}>
                        <Box ml={2}>
                          {showSearch ? (
                            <TextField
                              size="small"
                              value={searchQuery}
                              onChange={(e) => {
                                setSearchQuery(e.target.value);
                                handleSearchChange(e.target.value);
                              }}
                              placeholder={t("Search...")}
                              inputRef={searchRef}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <Tooltip title={t("Search")}>
                              <IconButton 
                                aria-label="Search" 
                                onClick={() => setShowSearch(true)}
                                size="small"
                                sx={{
                                  backgroundColor: 'white',
                                  color: '#1c4ed8',
                                  borderColor: '#1c4ed8',
                                  border: '1px solid',
                                  alignItems: "center",
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                    </ClickAwayListener>

                    <Tooltip title={t("More options")}>
                      <IconButton
                        aria-label="More options"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem 
                        onClick={handleDelete} 
                        disabled={checkedInvoiceIds.length === 0}
                      >
                        {deleteLoading ? <CircularProgress size={20} style={{ color: "#1c4ed8" }} /> : null}
                        <DeleteIcon sx={{ mr: 1 }} />
                        {t("Delete")}
                      </MenuItem>
                      <MenuItem 
                        onClick={handleDownloadClick} 
                        disabled={checkedInvoiceIds.length === 0}
                      >
                        <DownloadIcon sx={{ mr: 1 }} />
                        {t("Download")}
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box display="flex" justifyContent="right" alignItems="center">
                    <Box 
                        justifyContent="right" 
                        width={"100%"} 
                      >
                      <Button
                          variant="contained"
                          size="small"
                          startIcon={<AddIcon />}
                          className="button"
                          onClick={handleAddClick}
                          sx={{ borderRadius: '50px'}}
                        >
                          Create Invoice
                        </Button>
                      </Box>
                    </Box>
                </Box>

                {showUploadFrame && <UploadFrame onClose={handleCloseUploadFrame} />}
                {showDownloadFrame && <DownloadFrame 
                                        onClose={handleCloseDownloadFrame} 
                                        checkedInvoiceIds={checkedInvoiceIds}
                                        url={url} />}
                {showAddFrame && <AddFrame onClose={handleCloseAddFrame} />}

                <motion.div {...fadeIn}>
                  <DataGrid
                    rows={invoices}
                    columns={columns}
                    checkboxSelection
                    onRowClick={handleRowClick}
                    onRowSelectionModelChange={(newSelectionModel) => {
                      setCheckedInvoiceIds(newSelectionModel);
                    }}
                    sx={{
                      height: '65vh',
                      borderRadius: '20px',
                      cursor: 'pointer',
                      backgroundColor: 'white',
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                    rowHeight={80}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={i18n.language !== 'en' ? plPL.components.MuiDataGrid.defaultProps.localeText : {}}
                    slots={{
                      noRowsOverlay: () => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          {t("No invoices to display.")}
                        </Box>
                      ),
                    }}             
                  />
                </motion.div>
              </Box>
            </Container>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
