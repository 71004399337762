import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Button, TextField, InputAdornment, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import "./style.css";

export const SignupForm = (): JSX.Element => {
  const [email, setEmail] = useState('');
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission here, e.g., send email to server
  };

  return (
    <Box display="flex" sx={{ textAlign: 'left', mb: 25, mt: 5 }}> 
      <Box >
        <Typography variant="h3" gutterBottom>
          {t("It's getting better everyday")}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: 'text.secondary', maxWidth: '600px' }}
        >
          {t("Get in touch with us to gain information about future updates.")}
        </Typography>
      </Box>
      < Box >
      <form className="sign-up-form" onSubmit={handleSubmit}>
        <Box display="flex"  gap={2}> {/* Flex container for side-by-side layout */}
        <TextField
            type="email"
            id="email"
            size="small"
            placeholder={t("Enter your email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px", // Ensures input field inside is also circular
                border: "1px solid black", // Adds a border
              },
            }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            type="submit" // Added type for button
            className='btn'
            sx={{
              '&:hover': {
                backgroundColor: '#1c4ed8', // Example hover color
                color: 'white',
              }, borderRadius: '50px',
            }}
          >
            {t("Sign Up For Updates")}
          </Button>
        </Box>
      </form>
      </Box>
    </Box>
    
  );
};

export default SignupForm;

