//AuthContext.js
import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const externalUrl = process.env.REACT_APP_EXTERNAL_URL;
  const port = process.env.REACT_APP_PORT;
  const url = internalUrl ? `http://internalUrl:${port}` : externalUrl ? `https://${externalUrl}` : 'http://127.0.0.1:8000';
  const { t, i18n } = useTranslation();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loginUser = async (username, password, setLoginError, accessToken = null, refreshToken = null) => {
    try {
      let data;
      
      if (accessToken && refreshToken) {
        // Handle Google OAuth login
        data = {
          access: accessToken,
          refresh: refreshToken
        };
      } else {
        // Handle regular login
        const response = await fetch(`${url}/api/token/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
          },
          credentials: 'include',
          body: JSON.stringify({
            username: username.trim(),
            password
          })
        });

        data = await response.json();
        if (response.status !== 200) {
          if (response.status === 401) {
            setLoginError(t('Login unsuccessful. Please verify your credentials and try again.'));
          } else {
            setLoginError(t('An unexpected error occurred. Please try again.'));
          }
          return [false, null];
        }
      }

      // Common logic for both login methods
      setAuthTokens(data);
      const decodedToken = jwtDecode(data.access);
      setUser(decodedToken);
      localStorage.setItem("authTokens", JSON.stringify(data));
      setLoginError('');
      return [true, data];

    } catch (error) {
      console.error('Login error:', error);
      setLoginError(t('An unexpected error occurred. Please try again.'));
      return [false, null];
    }
  };

  const registerUser = async (username, password, first_name, last_name, email, company, 
                              company_address, industry, referral,
                              setRegisterError) => {
    const language = i18n.language;
    const normalizeLanguage = (lng) => {
      const mapping = {
        'en-GB': 'en',
        'en-US': 'en',
        'pl-PL': 'pl'
      };
      return mapping[lng] || lng.split('-')[0];
    };
    const normalizedLanguage = normalizeLanguage(language);
    try {
      const response = await fetch(`${url}/api/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          password,
          first_name,
          last_name,
          email,
          company, 
          company_address,
          industry,
          referral,
          language: normalizedLanguage
        })
      });
      if (response.status === 201) {
        localStorage.setItem("email", email);
        navigate("/verify");
      } else {
        const errorData = await response.json();
        if (errorData.username) {
          setRegisterError(errorData.username[0]); // Get the first error message for username
        } else {
          setRegisterError(t('Sign Up failed. Please try again.'));
        }
      }
    } catch (error) {
      setRegisterError(error|| t('Sign Up failed. Please try again.'));
    }
  };

  const verifyUser = async (code,  setCodeErrorMessage) => {
    try {
      const response = await fetch(`${url}/api/verify/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "code" : code
        })
      });
      if (response.status === 200) {
        localStorage.setItem("registerMessage", t("Registration successful! Please log in."));
        localStorage.removeItem("email");
        navigate("/login");
      } else {
        setCodeErrorMessage(t('An unexpected error occurred. Please try again.'));
      }
    } catch (error) {
      setCodeErrorMessage(t('Please enter a valid verification code.'));
    }
  };

  const logoutUser = async () => {
    try {
      // Clear user-related state
      setAuthTokens(null);
      setUser(null);
  
      // Remove user data from localStorage
      localStorage.removeItem("authTokens");
      localStorage.removeItem("username");
      localStorage.removeItem("company");
      localStorage.removeItem("email");
      localStorage.removeItem("cachedBusinessName");
      localStorage.removeItem("proSubscription");
      localStorage.removeItem("cachedBusinessLocations");
      localStorage.removeItem("cachedBusinessLocation");
      localStorage.removeItem("defaultCurrency");
      localStorage.removeItem("paymentAccess");
  
      // Redirect user to the home page
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
      // Optionally, show an error message to the user
    }
  };
  


  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    verifyUser,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);


  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};