//useAxios.js
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";

// Create a singleton for managing refresh state across hook instances
const refreshManager = {
  isRefreshing: false,
  refreshSubscribers: [],
  refreshPromise: null,
  
  subscribeToTokenRefresh(callback) {
    this.refreshSubscribers.push(callback);
  },
  
  onTokenRefreshed(newToken) {
    this.refreshSubscribers.forEach(callback => callback(newToken));
    this.refreshSubscribers = [];
  },
  
  resetRefreshState() {
    this.isRefreshing = false;
    this.refreshSubscribers = [];
    this.refreshPromise = null;
  }
};

const useAxios = () => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const externalUrl = process.env.REACT_APP_EXTERNAL_URL;
  const port = process.env.REACT_APP_PORT;
  const url = internalUrl ? `http://internalUrl:${port}` : externalUrl ? `https://${externalUrl}` : 'http://127.0.0.1:8000';

  const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    url,
    headers: authTokens ? { Authorization: `Bearer ${authTokens.access}` } : {}
  });

  // Clean up function to handle component unmounting
  useEffect(() => {
    return () => {
      // If this instance initiated a refresh that's still in progress, clean it up
      if (refreshManager.isRefreshing) {
        refreshManager.resetRefreshState();
      }
    };
  }, []);

  axiosInstance.interceptors.request.use(async req => {
    if (!authTokens) return req;

    const user = jwtDecode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    // If token is expired, handle refresh
    if (!refreshManager.isRefreshing) {
      refreshManager.isRefreshing = true;
      refreshManager.refreshPromise = (async () => {
        try {
          const response = await axios.post(`${url}/api/token/refresh/`, {
            refresh: authTokens.refresh
          });

          const newTokens = response.data;
          localStorage.setItem("authTokens", JSON.stringify(newTokens));
          setAuthTokens(newTokens);
          setUser(jwtDecode(newTokens.access));
          
          // Notify all subscribers about the new token
          refreshManager.onTokenRefreshed(newTokens.access);
          return newTokens.access;
        } catch (error) {
          console.error("Token refresh failed:", error);
          // Handle refresh token failure
          await logoutUser();
          navigate('/login');
          throw error;
        } finally {
          refreshManager.isRefreshing = false;
          refreshManager.refreshPromise = null;
        }
      })();
    }

    try {
      // Wait for the refresh promise to resolve
      const newToken = await refreshManager.refreshPromise;
      req.headers.Authorization = `Bearer ${newToken}`;
      return req;
    } catch (error) {
      // If the refresh failed, the error will be thrown from the promise
      throw error;
    }
  });

  // Add response interceptor to handle token errors
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      
      // If the error is due to an invalid token and we haven't tried to retry yet
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        
        // Force token refresh
        try {
          refreshManager.resetRefreshState(); // Reset the state to force a new refresh
          const response = await axios.post(`${url}/api/token/refresh/`, {
            refresh: authTokens.refresh
          });
          
          const newTokens = response.data;
          localStorage.setItem("authTokens", JSON.stringify(newTokens));
          setAuthTokens(newTokens);
          setUser(jwtDecode(newTokens.access));
          
          // Update the failed request with the new token
          originalRequest.headers.Authorization = `Bearer ${newTokens.access}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // If refresh fails, logout
          await logoutUser();
          navigate('/login');
          return Promise.reject(refreshError);
        }
      }
      
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;