import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

export const DocumentContent = ({ path, onDocumentLoadSuccess, pageNumber, scale }) => {

  return (
    <div style={{ position: 'relative' }}>
      <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
        <Page 
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={scale}
        />
      </Document>
    </div>
  );
};
