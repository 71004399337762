import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import { useNavigate} from 'react-router-dom';

export default function HighlightedCard() {
  const navigate = useNavigate();
  const handleGoDashboard = () => navigate('/dashboard');

  return (
    <Card variant="outlined" sx={{ height: '100%', borderRadius: '20px' }}>
      <CardContent>
        <InsightsRoundedIcon />
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: '600' }}
        >
          Explore Your Invoices
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: '8px' }}>
          Uncover insights and start asking questions to your personal assistant.
        </Typography>
        <Button
          variant="outlined"
          size="small"
          endIcon={<ChevronRightRoundedIcon />}
          onClick={handleGoDashboard}
          sx={{
            borderRadius: '50px',
            mt: 2,
            color: 'white',
            backgroundColor: '#1c4ed8',
            '&:hover': { backgroundColor: '#163bb7' },
          }}
        >
          Get insights
        </Button>
      </CardContent>
    </Card>
  );
}