import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { useTranslation } from 'react-i18next';



export default function Content() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
    
  const handleGoHome = () => {
    navigate('/');
  };
  
  const items = [
    {
      icon: <SettingsSuggestRoundedIcon sx={{ color: 'text.secondary' }} />,
      title: t('Adaptable performance'),
      description:
        t('Our product effortlessly adjusts to your needs, boosting efficiency and simplifying your tasks.'),
    },
    {
      icon: <ConstructionRoundedIcon sx={{ color: 'text.secondary' }} />,
      title: t('Built to last'),
      description:
        t('Experience unmatched durability that goes above and beyond with lasting investment.'),
    },
    {
      icon: <ThumbUpAltRoundedIcon sx={{ color: 'text.secondary' }} />,
      title: t('Great user experience'),
      description:
        t('Integrate our product into your routine with an intuitive and easy-to-use interface.'),
    },
    {
      icon: <AutoFixHighRoundedIcon sx={{ color: 'text.secondary' }} />,
      title: t('Innovative functionality'),
      description:
        t('Stay ahead with features that set new standards, addressing your evolving needs better than the rest.'),
    },
  ];
  return (
    <Stack
      sx={{ flexDirection: 'column', alignSelf: 'center', gap: 4, maxWidth: 450 }}
    >
      <Box onClick={handleGoHome} sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', pb: 5, cursor: 'pointer' }}>
        <img
          src="https://c.animaapp.com/kAl1n2qv/img/yourdraftlogo-v1-2@2x.png"
          alt="Company Logo"
          style={{ width: "52.1px", height: "51px" }}
        />
        <Typography variant="h4" sx={{ ml: 1, color: "#004080"}} >
          YourDraft
        </Typography>
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom variant="body1" sx={{ fontWeight: 'medium', color: '#1c4ed8' }}>
              {item.title}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}