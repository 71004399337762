// src/polyfills.js

// Polyfill for Promise.withResolvers
if (typeof Promise.withResolvers !== 'function') {
    Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }
  
  // Add more polyfills if needed (e.g., Array.prototype.at, etc.)
  