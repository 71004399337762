import React from 'react';
import { useContext } from "react";
import { Box, Button, Typography, Grid, Chip } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../../utils/AuthContext";
import { useTranslation } from 'react-i18next';

const fadeIn = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

export const HeroSection = () => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const handleSignIn = () => {
      if (user) {
          navigate('/dashboard');
      } else {
          navigate('/login'); 
      }
  };

  return (
      <Grid container spacing={5} alignItems="center" justifyContent="center" sx={{ pt: 15 }}>
        {/* Chip positioned in the middle, spanning full width */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Chip
              label={t("New: AI-Powered Invoice Analysis ✨")}
              variant="outlined"
              sx={{ fontSize: '1rem', padding: '0.5rem 1.5rem', color: 'primary.main', borderColor: 'primary.main' }}
            />
          </Box>
        </Grid>
  
        {/* Left Column (Text Content) */}
        <Grid item xs={12} md={6} >
          <Box sx={{ pb: 3, ml: 2 }}>
            <motion.div {...fadeIn}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 'bold',
                  mb: 3,
                }}
              >
                {t("Transform Your")} <Box component="span" sx={{ color: 'primary.main' }}>{t("Invoice Processing")}</Box>
                <br />
                {t("with AI Intelligence")}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mb: 4,
                  color: 'text.secondary',
                  maxWidth: '800px',
                  mx: 'auto',
                }}
              >
                {t("Automate your workflow and gain actionable insights with our enterprise-grade platform.")}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2}}>
                  <Button
                    variant="contained"
                    className="button"
                    color="primary"
                    size="large"
                    endIcon={<ArrowRight />}
                    onClick={handleSignIn}
                    sx={{ px: 4, py: 1.5, fontSize: '1rem', borderRadius: '50px', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", }}
                    >
                    {t("Start Now")}
                  </Button>
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={handleSignIn}
                    sx={{ px: 4, py: 1.5, fontSize: '1rem', borderRadius: '50px', backgroundColor: 'white',
                      borderColor: '#1c4ed8', color: '#1c4ed8', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                     }}
                    >
                    {t("See How It Works")}
                  </Button>
              </Box>
            </motion.div>
          </Box>
        </Grid>
  
        {/* Right Column (Image) */}
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.2 }}>
            <Box
              component="img"
              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/girl-shopping-list.svg"
              alt="Shopping Illustration"
              sx={{ width: '100%' }}
            />
          </motion.div>
        </Grid>
      </Grid>
    );
  }