import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';


export default function Content() {

  return (
    <Stack
      sx={{ flexDirection: 'column', alignSelf: 'center', gap: 5, maxWidth: 550 }}
    >
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', pb: 5}}>
        <img
          src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/girl-shopping-list.svg"
          alt="Company Logo"
          style={{ 
            transform: 'scaleX(-1)',
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            p: 2,
            mx: 'auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            backgroundSize: 'large', 
            backdropFilter: "blur(75px)", // Optional blur effect
          }}
        />
      </Box>
    </Stack>
  );
}