import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { motion } from 'framer-motion';
import XeroIcon from '../../pages/Settings/xero-logo.png';
import QuickbooksIcon from '../../pages/Settings/quickbooks-logo.png';
import SageIcon from '../../pages/Settings/sage-logo.png';
import { useTranslation } from 'react-i18next';

export const IntegrationFrame = () => {
  const { t } = useTranslation();
  const integrations = [
    { name: t('Xero'), icon: XeroIcon },
    { name: t('Quickbooks'), icon: QuickbooksIcon },
    { name: t('Sage'), icon: SageIcon },
  ];

  return (
    <Box component={motion.div} sx={{ mb: 18 }}>
      <Box
        sx={{
          position: 'relative',
          pt: 8,
          pb: 12,
        }}
      >
        <Typography
          variant="h4" 
          component="h3"
          sx={{ 
            textAlign: 'center', 
            mb: 3,
            fontWeight: 500,
            fontFamily: '"Funnel Display"'
          }}
        >
          {t('Our integrations')}
        </Typography>
      </Box>

      <Container maxWidth="lg">
        <Box
          sx={{
            mt: -6,
            display: 'flex',
            gap: 6,
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'center', md: 'flex-start' },
          }}
        >
          <Box
            component={motion.div}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            sx={{ 
              flex: '0 0 30%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1.5rem', md: '2rem' },
                fontWeight: '400',
                color: 'black',
                mb: 3,
                fontFamily: '"Funnel Display"'
              }}
            >
              {t('Accounting platforms')}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.125rem',
                lineHeight: 1.7,
                color: 'black',
                fontFamily: '"Funnel Display"'
              }}
            >
              {t('Seamlessly connect your accounting software with our secure integrations.')}
            </Typography>
          </Box>

          <Box
            sx={{
              flex: '0 0 67%',
              display: 'flex',
              gap: { xs: 4, sm: 3 },
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'center',
            }}
          >
            {integrations.map((integration, index) => (
              <Box
                component={motion.div}
                key={integration.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  duration: 0.8, 
                  delay: 0.3 + index * 0.1,
                  type: "spring",
                  stiffness: 100
                }}
                sx={{
                  flex: 1,
                  background: 'white',
                  backdropFilter: 'blur(8px)',
                  boxShadow: '0 4px 20px -1px rgba(0, 0, 0, 0.08)',
                  p: { xs: 4, sm: 3 },
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 3,
                  cursor: 'pointer',
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
                    opacity: 0,
                    transition: 'opacity 0.3s ease-in-out',
                  },
                  '&:hover': {
                    transform: 'translateY(-8px) scale(1.02)',
                    boxShadow: '0 20px 30px -10px rgba(0, 0, 0, 0.15)',
                    '&:before': {
                      opacity: 1,
                    },
                    '& img': {
                      transform: 'scale(1.08)',
                      filter: 'brightness(1.05)',
                    },
                  },
                }}
              >
                <Box
                  component="img"
                  src={integration.icon}
                  alt={`${integration.name} icon`}
                  sx={{
                    width: { xs: 110, sm: 95 },
                    height: { xs: 110, sm: 95 },
                    objectFit: 'contain',
                    transition: 'all 0.4s ease-in-out',
                  }}
                />
                <Typography
                  sx={{
                    color: '#1E1B4B',
                    fontSize: { xs: '1.5rem', sm: '1.25rem' },
                    fontWeight: 600,
                    transition: 'all 0.3s ease-in-out',
                    textAlign: 'center',
                    position: 'relative',
                    fontFamily: '"Funnel Display"',
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -8,
                      left: '50%',
                      width: 0,
                      height: 2,
                      background: '#1E1B4B',
                      transition: 'all 0.3s ease-in-out',
                      transform: 'translateX(-50%)',
                    },
                    '.MuiBox-root:hover &:after': {
                      width: '30%',
                    },
                  }}
                >
                  {integration.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <Typography
            sx={{
              color: 'black',
              fontSize: '1rem',
              fontStyle: 'italic',
              fontFamily: '"Funnel Display"'
            }}
          >
            {t('Need a different integration? Contact us to discuss custom integration options.')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

