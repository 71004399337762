import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Box, Stack, LinearProgress, linearProgressClasses } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import { useDrawingArea } from '@mui/x-charts/hooks';

// Helper Component for the center label in the pie chart
const StyledText = styled('text', {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: (theme.vars || theme).palette.text.secondary,
  variants: [
    {
      props: {
        variant: 'primary',
      },
      style: {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.h5.fontWeight,
      },
    },
    {
      props: { variant: 'secondary' },
      style: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
      },
    },
  ],
}));

function PieCenterLabel({ primaryText, secondaryText }) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <>
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </>
  );
}

PieCenterLabel.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
};

// Main InvoiceStatusChart Component
const InvoiceStatusChart = ({ data }) => {
  const statusCount = data.reduce((counts, invoice) => {
    counts[invoice.status] = (counts[invoice.status] || 0) + 1;
    return counts;
  }, {});

  const chartData = Object.entries(statusCount).map(([status, count]) => ({
    label: status,
    value: count,
  }));

  const totalInvoices = chartData.reduce((sum, { value }) => sum + value, 0);

  const colors = {
    'Pending Review': 'hsl(40, 70%, 60%)',
    'In Review': '#60A5FA',
    'Approved': '#34D399',
    'Rejected': 'hsl(220, 20%, 30%)',
  };

  return (
    <Card variant="outlined" sx={{ width: '400px', borderRadius: '20px' }}>
      <CardContent>
        <Typography component="h4" variant="subtitle2 bold">
          Invoice Status Distribution
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PieChart
            colors={Object.values(colors)}
            margin={{
              left: 80,
              right: 80,
              top: 80,
              bottom: 80,
            }}
            series={[
              {
                data: chartData,
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 5,
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            height={260}
            width={260}
            slotProps={{
              legend: { hidden: true },
            }}
          >
            <PieCenterLabel primaryText={`${totalInvoices}`} secondaryText="Total Invoices" />
          </PieChart>
        </Box>
        {chartData.map((entry, index) => (
          <Stack key={index} direction="row" sx={{ alignItems: 'center', gap: 2, pb: 2 }}>
            <Stack sx={{ gap: 1, flexGrow: 1 }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: '500' }}>
                  {entry.label}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {((entry.value / totalInvoices) * 100).toFixed(1)}%
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                aria-label={`Number of invoices ${entry.label}`}
                value={(entry.value / totalInvoices) * 100}
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: colors[entry.label],
                  },
                }}
              />
            </Stack>
          </Stack>
        ))}
      </CardContent>
    </Card>
  );
};

InvoiceStatusChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default InvoiceStatusChart;

