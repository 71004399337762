import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { InvoiceDetailView } from './pages/InvoiceDetailView/InvoiceDetailView';
import { ContactUs } from './pages/ContactUs/ContactUs';
import { Demo } from './pages/DemoFrame/DemoFrame';
import SignInSide from './pages/SignIn/SignInSide';
import SignUpSide from './pages/SignUp/SignUpSide';
import VerifySide from './pages/SignUp/VerifySide';
import { AnalyticsView } from './pages/Analytics/AnalyticsView';
import { Copyright } from './components/Copyright/Copyright';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './utils/AuthContext';
import { startKeepAlive } from './utils/keepAlive';
import { PricingView } from './pages/Pricing/PricingView';
import { MembershipView } from './pages/Membership/MembershipView';

function App() {
  
  useEffect(() => {
    startKeepAlive();
  }, []);

  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <Routes>

            {/* Login Required Pages */}
            <Route element={<PrivateRoute />}>
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              {/* <Route path="/analytics" element={<AnalyticsViewAuth />} /> */}
              {/* <Route path="/invoice/:invoiceId" element={<InvoiceDetailViewAuth />} /> */}
              <Route path="/membership" element={<MembershipView />} />
            </Route>

            {/* Login Not Required Pages - For Demo Purposes */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/analytics" element={<AnalyticsView />} />
            <Route path="/invoice/:invoiceId" element={<InvoiceDetailView />} />

            {/* Login Not Required Pages */}
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<PricingView />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/login" element={<SignInSide />} />
            <Route path="/register" element={<SignUpSide />} />
            <Route path="/verify" element={<VerifySide />} />

          </Routes>
        </AuthProvider>
        <Copyright sx={{ my: 4 }} />
      </div>
    </Router>
  );
}

export default App;

