import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Alert, 
  AlertTitle,
  IconButton,
  LinearProgress, 
  Tabs, 
  Tab, 
  Grid,
  Chip,
  Button,
  CircularProgress
} from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { CreditCard, FileText, Settings, Activity, User, X } from 'lucide-react';
import { Navbar } from "../../components/Navbar/Navbar";
import SideBar from "../../components/SideBar/SideBar";
import useAxios from "../../utils/useAxios";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`membership-tabpanel-${index}`}
    aria-labelledby={`membership-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const ProfileCard = ({ data }) => (
  <Card>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <User size={24} style={{ marginRight: 8 }} />
        <Typography variant="h6" color='#1c4ed8'>Profile</Typography>
      </Box>
      {[
        { 
          label: 'Name', 
          value: `${data?.subscription?.user?.first_name} ${data?.subscription?.user?.last_name}`.trim() || 'Not specified'
        },
        { label: 'Email', value: data?.subscription?.user?.email },
        { label: 'Company', value: data?.subscription?.customer?.company_name || 'Not specified' }
      ].map(({ label, value }) => (
        <Box key={label} sx={{ mb: label !== 'Company' ? 2 : 0 }}>
          <Typography variant="subtitle2" color="text.secondary">{label}</Typography>
          <Typography>{value}</Typography>
        </Box>
      ))}
    </CardContent>
  </Card>
);

const SubscriptionCard = ({ data }) => {
  const formatDate = (dateString) => {
    if (!dateString) return 'Not available';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CreditCard size={24} style={{ marginRight: 8 }} />
          <Typography variant="h6" color='#1c4ed8' >Subscription</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">Current Plan</Typography>
          <Typography>{data?.subscription?.plan_name || 'No active plan'}</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">Status</Typography>
          <Chip 
            label={data?.subscription?.status ? data?.subscription?.status : "Inactive"}
            color={data?.subscription?.status === "active" ? 'success' : 'warning'}
            size="small"
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">Billing Type</Typography>
          <Typography>
            {data?.subscription?.price_details?.price_display || 'Not specified'}
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">Current Period</Typography>
          <Typography>
            {`${formatDate(data?.subscription?.billing_period?.current_period_start)} - ${formatDate(data?.subscription?.billing_period?.current_period_end)}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const UsageCard = ({ data }) => {
  const usageStats = data?.subscription?.usage_stats || {};
  const isUnlimited = usageStats.monthly_quota === "Unlimited";

  const getCurrencySymbol = (currency) => {
    switch (currency.toUpperCase()) {
      case "USD": return "$";
      case "EUR": return "€";
      case "GBP": return "£";
      // Add more cases as needed
      default: return currency.toUpperCase();
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Activity size={24} style={{ marginRight: 8 }} />
          <Typography variant="h6" color='#1c4ed8' >Usage</Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="subtitle2" color='#1c4ed8' >Documents Processed</Typography>
            <Typography variant="subtitle2">
              {isUnlimited ? 
                `${usageStats.documents_processed} / ∞` : 
                `${usageStats.documents_processed || 0} / ${usageStats.monthly_quota || 0}`
              }
            </Typography>
          </Box>
          {!isUnlimited && (
            <LinearProgress 
              variant="determinate" 
              value={usageStats.usage_percentage || 0}
              color={
                usageStats.usage_percentage > 90 ? 'error' : 
                usageStats.usage_percentage > 75 ? 'warning' : 
                'primary'
              }
            />
          )}
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Remaining this period
          </Typography>
          <Typography>
            {isUnlimited ? 
              'Unlimited' : 
              `${usageStats.remaining_invoices || 0} documents`
            }
          </Typography>
        </Box>
        {data?.billing_period && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Days left in billing period
            </Typography>
            <Typography>
              {data?.subscription?.billing_period.days_remaining} days
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Upcoming Bill
          </Typography>
          <Typography>
            {data?.subscription?.price_details?.currency
              ? `${getCurrencySymbol(data.subscription.price_details.currency)}${usageStats.upcoming_bill ? (usageStats.upcoming_bill / 100).toFixed(2) : "0.00"} ${data.subscription.price_details.currency.toUpperCase()}`
              : "0.00"}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const MembershipView = () => {
  const [openSideBar, setOpenSideBar] = useState(true);
  const [status, setStatus] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [membershipData, setMembershipData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [inactive, setInactive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const axios = useAxios();
  const checkoutProcessed = useRef(false);

  const sessionId = useMemo(() => 
    new URLSearchParams(location.search).get("session_id"),
    [location.search]
  );

  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );

  const handleAlertClose = () => {
    setStatus(null);
    navigate(location.pathname, { 
      replace: true,
      state: { checkoutComplete: true }
    });
  };

  const handleChange = () => {
    navigate('/pricing');
  };

  const handleCancel = async () => {
    try {
      setCancelLoading(true);
      await axios.post(`${url}/api/subscription/cancel/`);
  
      setStatus({
        severity: 'success',
        message: 'The membership has been cancelled.'
      });
    } catch (error) {
      if (error.response?.status === 404) {
        setInactive(true);
        setStatus({
          severity: 'error',
          message: 'No active membership found. Please sign up or contact support for assistance.'
        });
      } else {
        console.error("Failed to cancel membership:", error);
        setStatus({
          severity: 'error',
          message: error.response?.data?.message || 'Failed to cancel membership. Contact support team.'
        });
      }
    } finally {
      setCancelLoading(false);
    }
  };

  const fetchMembershipData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${url}/api/subscription/membership/`);
      
      // Check if response status is 206 (Partial Content)
      if (response.status === 206) {
        setStatus({
          severity: 'warning',
          message: 'No active membership found. Please start or renew your subscription.'
        });
        setInactive(true);
        setMembershipData(response.data);
      } else if (response.data?.subscription?.status !== 'active'){
        setInactive(true);
        setMembershipData(response.data);
      } else {
        setMembershipData(response.data);
      }
    } catch (error) {
      console.error("Error fetching membership data:", error);
      setStatus({
        severity: 'error',
        message: 'Failed to load membership data. Please try again later.'
      });
    } finally {
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchMembershipData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    const finalizeCheckout = async () => {
      if (!sessionId || checkoutProcessed.current) return;
      
      checkoutProcessed.current = true;
      
      try {
        await axios.post(`${url}/api/checkout/checkout_finalize/?session_id=${sessionId}`);
        setStatus({ 
          severity: 'success',
          message: 'Subscription successfully activated!'
        });
        fetchMembershipData();
      } catch (error) {
        console.error("Error finalizing checkout:", error);
        setStatus({ 
          severity: 'error',
          message: 'Failed to finalize subscription. Please contact support.'
        });
      }
    };

    finalizeCheckout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (sessionId && location.state?.checkoutComplete) {
      navigate(location.pathname, { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, location.state]);

  // Updated Recent Activity tab
  const tabs = [
    {
      label: 'Recent Uploads',
      icon: FileText,
      content: membershipData?.recent_activity?.length > 0 
        ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>File</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {membershipData.recent_activity.map((activity, index) => (
                    <TableRow key={index}>
                      <TableCell>{new Date(activity.updated_at).toLocaleString()}</TableCell>
                      <TableCell>{activity.file.split('/').pop()}</TableCell>
                      <TableCell>{activity.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        : 'No recent activity to display',
    },
    {
      label: 'Settings',
      icon: Settings,
      content: (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 35, justifyContent: 'center' }}>
            <Button onClick={handleChange} variant="contained" color="primary" sx={{ backgroundColor: '#1c4ed8' }}>
              {inactive ? "Subscribe Now" : "Change Membership"}
            </Button>
            <Button onClick={handleCancel} variant="contained" color="error" disabled={cancelLoading || inactive}>
              {cancelLoading ? <CircularProgress size={24} color="inherit" sx={{ marginRight: 1 }} /> : null}
              Cancel Membership
            </Button>
          </Box>
        </Box>
      )      
    },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <LinearProgress sx={{ width: '50%' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', bgcolor: 'background.default' }}>
      <SideBar 
        open={openSideBar} 
        handleDrawerToggle={() => setOpenSideBar(!openSideBar)} 
        demo={false}
      />
      <Navbar sideBarOpen={openSideBar} />
      
      <Box component="main" sx={{
        flexGrow: 1,
        mt: 15,
        mb: 18,
        pl: openSideBar ? 10 : 0,
        pr: 20,
      }}>
        {status && (
          <Alert 
            severity={status.severity}
            sx={{ mb: 3 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <X size={16} />
              </IconButton>
            }
          >
            <AlertTitle>{status.severity === 'success' ? 'Success' : 'Alert'}</AlertTitle>
            {status.message}
          </Alert>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <ProfileCard data={membershipData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SubscriptionCard data={membershipData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <UsageCard data={membershipData} />
          </Grid>
        </Grid>

        <Card sx={{ mt: 3, width: '100%', height: '60%', overflow: 'auto' }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          sx={{
            borderColor: 'divider',
            color: '#1c4ed8',
            borderBottom: 0.1,
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
              gap: 35, // Adds consistent spacing between tabs
            },
          }}
          centered
          variant="standard"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              icon={React.createElement(tab.icon, { size: 16 })}
              iconPosition="start"
              label={tab.label}
              sx={{
                width: 200, // Sets a consistent width for tabs
                textTransform: 'none', // Prevents all-caps text
                fontSize: '1rem',
                fontWeight: 500,
                '& .MuiSvgIcon-root': {
                  marginRight: 1, // Consistent spacing between icon and label
                },
              }}
              id={`membership-tab-${index}`}
              aria-controls={`membership-tabpanel-${index}`}
            />
          ))}
        </Tabs>

        {tabs.map((tab, index) => (
          <TabPanel key={index} value={tabValue} index={index}>
            <Typography color="text.secondary">{tab.content}</Typography>
          </TabPanel>
        ))}
        </Card>
  
      </Box>
    </Box>
  );
};