import React, { useState, useCallback, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  AppBar,
  FormControl
} from "@mui/material";
import AuthContext from '../../utils/AuthContext';
import { PersonOutlined, Logout, EmojiEventsOutlined, VisibilityOutlined, DashboardOutlined, SettingsOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './LanguageSwitcher';

const StyledToolbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backdropFilter: 'blur(24px)',
  backgroundColor: '#fff',
  padding: '12px 40px',
  borderRadius: `20px`,
  WebkitBoxShadow: '0px 0px 25px -17px rgba(155, 188, 255, 1)',
  MozBoxShadow: '0px 0px 25px -17px rgba(155, 188, 255, 1)',
  boxShadow: '0px 0px 25px -17px rgba(155, 188, 255, 1)',
}));

export const Navbar = ({ sideBarOpen }) => {
  const demo = !localStorage.getItem("authTokens");
  const username = localStorage.getItem('username') || 'DemoUser';
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMembership = useCallback(() => navigate('/membership'), [navigate]);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const { t } = useTranslation();

  const handleGoDashboardDemo = () => {
    navigate('/dashboard')
  };

  const handleGoDashboard = () => navigate('/dashboard');
  const handleGoDemo = () => navigate('/demo');
  const handleLogin = () => navigate('/login');
  const handleGoHome = () => navigate('/');
  const handleGoContact = () => navigate('/contact');
  const handlePricing = () => navigate('/pricing');
  const { logoutUser } = useContext(AuthContext);
  const handleLogout = () => {
    logoutUser();
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    navigate('/settings');
  };

  const isHomePage = ["/", "/login", "/register"].includes(location.pathname);

  return (
    <AppBar sx={{ boxShadow: 0, mt: '5px', bgcolor: isHomePage ? 'transparent' : 'transparent', backgroundImage: 'none', zIndex: 2 }}>
      <Container sx={{ width: sideBarOpen ? '100%' : "70%" }} >
        <StyledToolbar >
          <Box display="flex" alignItems="center" onClick={handleGoHome} sx={{ cursor: 'pointer' }}>
            <img
              src="https://storageforinvoices.blob.core.windows.net/images/logo.png"
              alt="Company Logo"
              style={{ width: "170px", height: "40px" }}
            />
          </Box>

          {/* Desktop Menu */}
          <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center', textWrap: 'nowrap', display: { xs: 'none', md: 'flex' } }}>
            {isHomePage && demo && (
              <Box display="flex" justifyContent='center'>
                <Button
                  variant="outlined" startIcon={<VisibilityOutlined sx={{ alignItems: 'center' }}/>}
                  onClick={handleGoDashboardDemo}
                  sx={demoButtonStyles}
                >
                  {t('Try free demo')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleGoDemo}
                  sx={{ ...buttonStyles, fontFamily: '"Funnel Display"', textTransform: 'none', fontSize: '0.95rem' }}
                >
                  {t('How it works')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handlePricing}
                  sx={{ ...buttonStyles, fontFamily: '"Funnel Display"', textTransform: 'none', fontSize: '0.95rem' }}
                >
                  {t('Pricing')}
                </Button>
              </Box>
            )}
          </Box>

          <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center', textWrap: 'nowrap' }}>
            {demo ? (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  variant="outlined"
                  startIcon={<PersonOutlined sx={{ color: "#1c4ed8" }} />}
                  onClick={handleLogin}
                  sx={{ ...buttonStyles, fontFamily: '"Funnel Display"', textTransform: 'none', fontSize: '0.95rem' }}
                >
                  {t('Sign In')}
                </Button>
              </Box>
            ) : (
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "50px",
                    "&:hover": {
                      backgroundColor: alpha("#1c4ed8", 0.05),
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: "#1c4ed8",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={handleMenuOpen}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                    padding: "8px 12px",
                    borderRadius: "30px",
                    backgroundColor: alpha("#1c4ed8", 0.05),
                  }}
                >
                  <MenuIcon sx={{ color: "#1c4ed8" }} />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "black",
                      textTransform: "none",
                      fontFamily: '"Funnel Display"',
                      fontSize: '0.95rem'
                    }}
                  >
                    {t("Welcome back")}, {username}
                  </Typography>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  PaperProps={{
                    style: {
                      borderRadius: "10px",
                      overflow: "hidden",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleGoDashboard();
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px", // Adds space between the icon and text
                      "&:hover": {
                        backgroundColor: alpha("#1c4ed8", 0.1),
                      },
                    }}
                  >
                    <DashboardOutlined sx={{ color: "#1c4ed8" }} />
                    <Typography sx={{ fontWeight: 500, fontFamily: '"Funnel Display"', fontSize: '0.95rem' }}>
                      {t("Dashboard")}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleSettings();
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px", // Adds space between the icon and text
                      "&:hover": {
                        backgroundColor: alpha("#1c4ed8", 0.1),
                      },
                    }}
                  >
                    <SettingsOutlined sx={{ color: "#1c4ed8" }} />
                    <Typography sx={{ fontWeight: 500, fontFamily: '"Funnel Display"', fontSize: '0.95rem' }}>
                      {t("Settings")}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleMembership();
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px", // Adds space between the icon and text
                      "&:hover": {
                        backgroundColor: alpha("#1c4ed8", 0.1),
                      },
                    }}
                  >
                    <EmojiEventsOutlined sx={{ color: "#1c4ed8" }} />
                    <Typography sx={{ fontWeight: 500, fontFamily: '"Funnel Display"', fontSize: '0.95rem' }}>
                      {t("My Membership")}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleLogout();
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px", // Adds space between the icon and text
                      "&:hover": {
                        backgroundColor: alpha("#1c4ed8", 0.1),
                      },
                    }}
                  >
                    <Logout sx={{ color: "#1c4ed8" }} />
                    <Typography sx={{ fontWeight: 500, fontFamily: '"Funnel Display"', fontSize: '0.95rem' }}>
                      {t("Logout")}
                    </Typography>
                  </MenuItem>
                </Menu>
              </FormControl>
            )}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <LanguageSwitcher />
            </Box>
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <LanguageSwitcher />
            <IconButton onClick={toggleDrawer(true)}><MenuIcon /></IconButton>
            <Drawer 
              anchor="top" 
              open={open} 
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  width: '100%',
                  maxWidth: '100%',
                  borderRadius: '0 0 20px 20px',
                }
              }}
            >
              <Box sx={{ backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pt: 3, pl: 1, pb: 1 }}>
                  {isHomePage && demo && (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={<VisibilityOutlined />}
                        onClick={handleGoDashboardDemo}
                        sx={demoButtonStyles}
                      >
                        <span sx={{ fontFamily: '"Funnel Display"' }}>{t('Try Free Demo')}</span>
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleGoDemo}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('How It Works')}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handlePricing}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('Pricing')}
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<PersonOutlined sx={{ color: "#1c4ed8" }} />}
                        onClick={handleLogin}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('Sign In')}
                      </Button>
                    </>
                  )}
                  {!isHomePage && !demo && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={handleGoDashboard}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('Dashboard')}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleGoContact}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('Contact')}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handlePricing}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('Pricing')}
                      </Button>
                    </>
                  )}
                  {!isHomePage && demo && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={handleGoDemo}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('How It Works')}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handlePricing}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('Pricing')}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleGoContact}
                        sx={{ ...buttonStyles, fontFamily: '"Funnel Display"' }}
                      >
                        {t('Contact')}
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
};

// Button styles
const buttonStyles = {
  borderColor: 'transparent', 
  color: 'black',
  borderRadius: '50px',
};

const demoButtonStyles = {
  color: '#1c4ed8',
  borderColor: '#1c4ed8',
  borderRadius: '50px',
  textTransform: 'none',
  fontFamily: '"Funnel Display"',
  fontSize: '0.95rem',
  mr: '15px'
};
