import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export const TermsAndConditionsDialog = ({ open, onClose }) => {
    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    
    const handleDialogClose = () => {
        onClose();
    };

    return (
        <Dialog 
            open={open}
            onClose={handleDialogClose} 
            maxWidth="md" 
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '24px',
                    boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
                    padding: '16px'
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h5" fontWeight="bold">
                    {t("Terms and Conditions")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" paragraph>
                    {t("YourDraft provides a cutting-edge invoice processing platform powered by advanced technology. By accessing or using our services, you agree to be bound by the following terms and conditions.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("1. Acceptance of Terms")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("These Terms of Service (\"Terms\") constitute a legally binding agreement between you and YourDraft. By registering for an account, accessing, or using our platform, you explicitly acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use our services.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("2. Service Description")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("YourDraft offers an advanced AI-powered invoice processing platform designed to automate and streamline financial document management. Our service utilizes state-of-the-art machine learning algorithms to extract, validate, and process invoice data with exceptional accuracy and efficiency.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("3. User Account and Responsibilities")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("3.1 Account Creation. Users must provide accurate, current, and complete information during the registration process.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("3.2 Account Security. You are exclusively responsible for")}
                </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Maintaining the confidentiality of your account credentials")}
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Preventing unauthorized access to your account")}
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- All activities conducted under your account")}
                    </Typography>
                <Typography variant="body2" paragraph>
                    {t("3.3 You agree to immediately notify us of any suspected unauthorized use of your account.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("4. Data Privacy and Security")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("4.1 We employ industry-leading encryption and security protocols to protect your data.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("4.2 All invoice and financial data processed through our platform is treated with the highest level of confidentiality.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("4.3 We comply with relevant data protection regulations, including GDPR and CCPA, ensuring robust protection of your sensitive information.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("5. Intellectual Property Rights")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("5.1 All proprietary technologies, algorithms, and platform features are the exclusive intellectual property of YourDraft.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("5.2 Users are granted a limited, non-exclusive, non-transferable license to use our platform subject to these Terms.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("5.3 You may not reproduce, modify, reverse engineer, or create derivative works based on our platform's technology.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("6. Limitation of Liability")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("6.1 YourDraft provides services \"as is\" and makes no warranties about the absolute accuracy of AI-processed data.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("6.2 We are not liable for")}
                </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Indirect, incidental, or consequential damages")}
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Lost profits or business interruption")}
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Data processing errors beyond our reasonable control")}
                    </Typography>
                <Typography variant="body2" paragraph>
                    {t("6.3 Our total liability is limited to the amount paid by you for services in the preceding 12 months.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("7. Pricing and Billing")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("7.1 Subscription fees are charged based on selected service tiers and processing volume.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("7.2 Pricing is subject to change with 30 days' prior notice.")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("7.3 All fees are non-refundable unless specified in our separate Refund Policy.")}
                </Typography>

                <Typography variant="h6" gutterBottom>
                    {t("8. Termination")}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t("8.1 We reserve the right to suspend or terminate your account for")}
                </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Violation of these Terms")}
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Suspicious or fraudulent activity")}
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ paddingLeft: '10px' }}>
                        {t("- Non-payment of subscription fees")}
                    </Typography>
                <Typography variant="body2" paragraph>
                    {t("8.2 Upon termination, you lose access to the platform and stored data.")}
                </Typography>

                <Typography variant="body2" paragraph sx={{ marginTop: 2, fontStyle: 'italic' }}>
                    {t("Last Updated: December 2024")}
                </Typography>
                <Typography variant="body2" paragraph sx={{ marginTop: 2, fontStyle: 'italic' }}>
                    {t("For any questions or clarifications, please contact our support team at info@yourdraft.ai.")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleDialogClose}
                    sx={{
                        borderRadius: '50px', 
                        backgroundColor: 'white', 
                        borderColor: '#1c4ed8', 
                        color:'#1c4ed8',
                        textTransform: 'none'
                    }}
                >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TermsAndConditionsDialog;
