import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import "./style.css";

const Chatbot = ({ invoiceId }) => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const url = useMemo(
    () => (internalUrl ? `https://${internalUrl}` : "http://127.0.0.1:8000"),
    [internalUrl]
  );

  const messagesContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleSendMessage = async () => {
    const trimmedInput = input.trim();
    if (!trimmedInput || isLoading) return;
  
    const userMessage = { text: trimmedInput, sender: "user" };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsLoading(true);
  
    try {
      // Select the last 4 messages (or fewer if there are not enough)
      const conversationHistory = messages.slice(-4);
  
      const response = await fetch(`${url}/api/chat/${invoiceId}/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          message: userMessage.text,
          history: conversationHistory, // Include conversation history
        }),
      });
  
      const data = await response.json();
      const botMessage = {
        text: data.response || "Sorry, I didn't understand that.",
        sender: "bot",
      };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        { text: "An unexpected error occurred. Please try again.", sender: "system" },
      ]);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const clearMessages = () => setMessages([]);

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        <Typography color="#1c4ed8" variant="h6">
          Your Personal Assistant
        </Typography>
        <button onClick={clearMessages} className="clear-chat-btn" title="Clear Chat">
          <FontAwesomeIcon icon={faBroom} size="lg" /> Clear Chat
        </button>
      </div>

      <div ref={messagesContainerRef} className="chatbot-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="chatbot-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Type your message..."
          className="chatbot-input"
          disabled={isLoading}
        />
        <button onClick={handleSendMessage} className="chatbot-send-btn" disabled={isLoading}>
          {isLoading ? "Sending..." : "Send"}
        </button>
      </div>
    </div>
  );
};

export default Chatbot;


