import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./AuthContext";

const PrivateRoute = () => {
  const { user } = useContext(AuthContext);

  // If user is not authenticated, redirect to login, otherwise render the protected route
  return user ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
