import React from 'react';
import { Zap, Clock, TrendingUp } from 'lucide-react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';

export const StatSection = ({ startCountUp }) => {
  const { t } = useTranslation();

  const stats = [
    { 
      icon: TrendingUp, 
      end: 99, 
      suffix: '%',
      label: t('Accuracy rate'),
      color: '#1c4ed8' 
    },
    { 
      icon: Clock, 
      end: 70, 
      suffix: '%',
      label: t('Time saved'), 
      color: '#1c4ed8' 
    },
    { 
      icon: Zap, 
      end: 5, 
      suffix: 'x',
      label: t('Faster processing'), 
      color: '#1c4ed8' 
    }
  ];

  // Array state to track whether each CountUp animation has finished.
  const [countFinished, setCountFinished] = React.useState(new Array(stats.length).fill(false));

  return (
    <Box 
      sx={{
        width: '100vw',
        position: 'relative',
        marginLeft: '-50vw',
        left: '50%',
      }}
    >
      <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 3 }}>
        <Typography 
          variant="h4" 
          component="h3"
          sx={{ 
            textAlign: 'center', 
            mb: 5,
            fontWeight: 500,
            color: 'black',
            fontFamily: '"Funnel Display"'
          }}
        >
          {t('With YourDraft you get')}
        </Typography>
        <Grid container spacing={4}>
          {stats.map((stat, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  textAlign: 'center',
                  borderRadius: '20px',
                  width: '100%',
                  border: 0.5,
                  transition: 'transform 0.4s, box-shadow 0.4s',
                  '&:hover': { 
                    transform: 'scale(1) translateY(-15px)', 
                    transition: 'transform 0.4s, box-shadow 0.4s',
                    border: 1, 
                    borderColor: '#1c4ed8',
                    boxShadow: '0px 0px 25px -17px rgba(155, 188, 255, 1)',
                    WebkitBoxShadow: '0px 0px 25px -17px rgba(155, 188, 255, 1)',
                    MozBoxShadow: '0px 0px 25px -17px rgba(155, 188, 255, 1)',
                  },
                  position: 'relative',
                  zIndex: 1,
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                  }
                }}
              >
                <CardContent 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    p: 3 
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-block',
                      animation: countFinished[index] ? 'none' : 'pulse-animation 1s infinite',
                      '@keyframes pulse-animation': {
                        '0%': { transform: 'scale(1)' },
                        '50%': { transform: 'scale(1.2)' },
                        '100%': { transform: 'scale(1)' },
                      },
                    }}
                  >
                    <stat.icon 
                      size={42} 
                      strokeWidth={1.5} 
                      color={stat.color} 
                    />
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 1, 
                      fontWeight: 600, 
                      color: 'black',
                      fontFamily: '"Funnel Display Medium"'
                    }}
                  >
                    {startCountUp ? (
                      <CountUp 
                        start={0}
                        end={stat.end}
                        duration={2}
                        suffix={stat.suffix}
                        onEnd={() => {
                          // Once this CountUp animation ends, update state to stop the pulse.
                          setCountFinished(prev => {
                            const updated = [...prev];
                            updated[index] = true;
                            return updated;
                          });
                        }}
                      />
                    ) : (
                      // Show the initial value until animation starts
                      `0${stat.suffix}`
                    )}
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    mt={-1}
                    fontWeight={400}
                    color="black"
                    sx={{ fontFamily: '"Funnel Display"' }}
                  >
                    {stat.label}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};