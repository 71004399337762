// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    width: fit-content;
    background-color: #1c4ed8 !important;
    border-color: #1c4ed8 !important;
    color: white !important;
  }`, "",{"version":3,"sources":["webpack://./src/pages/ContactUs/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,gCAAgC;IAChC,uBAAuB;EACzB","sourcesContent":[".button {\n    width: fit-content;\n    background-color: #1c4ed8 !important;\n    border-color: #1c4ed8 !important;\n    color: white !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
